import React from 'react';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Footer from '../../../components/Footer';
import Content from '../components/Content';
import Scope from './components/Scope';
import Collection from './components/Collection';
import Purposes from './components/Purposes';
import Disclosures from './components/Disclosures';
import OptOut from './components/OptOut';
import Access from './components/Access';
import Security from './components/Security';
import Changes from './components/Changes';

const sections = [
  {
    id: 'Scope',
    title: 'Scope',
    component: Scope,
  },
  {
    id: 'Collection',
    title: 'Types of Data and Collection Methods',
    component: Collection,
  },
  {
    id: 'Purposes',
    title: 'Collection Purposes, Use of Data',
    component: Purposes,
  },
  {
    id: 'Disclosures',
    title: 'Disclosures of Personal Information',
    component: Disclosures,
  },
  {
    id: 'OptOut',
    title: 'Opt-Out',
    component: OptOut,
  },
  {
    id: 'Access',
    title: 'Access',
    component: Access,
  },
  {
    id: 'Security',
    title: 'Security',
    component: Security,
  },
  {
    id: 'Changes',
    title: 'Changes to the Policy',
    component: Changes,
  },
];

const PrivacyPolicy = () => (
  <>
    <Header />
    <Banner title="Privacy Policy" mobileTitle="Privacy Policy" />
    <Content sections={sections} />
    <Footer />
  </>
);

export default PrivacyPolicy;
