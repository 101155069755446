import React from 'react';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Footer from '../../../components/Footer';
import Content from '../components/Content';
import Acceptance from './components/Acceptance';
import Accessing from './components/Accessing';
import Rights from './components/Rights';
import Uses from './components/Uses';
import Reliance from './components/Reliance';
import ChangesWebsites from './components/ChangesWebsites';
import Information from './components/Information';
import LinksWebsites from './components/LinksWebsites';
import Limitation from './components/Limitation';
import Waiver from './components/Waiver';
import ChangesTerms from './components/ChangesTerms';
import Comments from './components/Comments';

const sections = [
  {
    id: 'Acceptance',
    title: 'Acceptance of the Terms',
    component: Acceptance,
  },
  {
    id: 'Accessing',
    title: 'Accessing these Websites',
    component: Accessing,
  },
  {
    id: 'Rights',
    title: 'Intellectual Property Rights',
    component: Rights,
  },
  {
    id: 'Uses',
    title: 'Prohibited Uses',
    component: Uses,
  },
  {
    id: 'Reliance',
    title: 'Reliance on Information Posted',
    component: Reliance,
  },
  {
    id: 'ChangesWebsites',
    title: 'Changes to these Websites',
    component: ChangesWebsites,
  },
  {
    id: 'Information',
    title: 'Information Privacy',
    component: Information,
  },
  {
    id: 'LinksWebsites',
    title: 'Links from these Websites',
    component: LinksWebsites,
  },
  {
    id: 'Limitation',
    title: 'Limitation on Liability',
    component: Limitation,
  },
  {
    id: 'Waiver',
    title: 'Waiver, Severability & Assignment',
    component: Waiver,
  },
  {
    id: 'ChangesTerms',
    title: 'Changes to these Terms',
    component: ChangesTerms,
  },
  {
    id: 'Comments',
    title: 'Comments and Concerns',
    component: Comments,
  },
];

const TermsOfUse = () => (
  <>
    <Header />
    <Banner title="Terms of Use" mobileTitle="Terms of Use" />
    <Content sections={sections} />
    <Footer />
  </>
);

export default TermsOfUse;
