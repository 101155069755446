import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Security = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Security</Title>
    <Description>
      Transmissions over the Internet are never 100% secure or error-free.
      However, we take reasonable steps to protect your personal information
      from loss, misuse, and unauthorized access, disclosure, alteration, and
      destruction.
    </Description>
  </Element>
);

export default Security;
