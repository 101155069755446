const featuresStyles = (theme) => ({
  feature: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
});

export default featuresStyles;
