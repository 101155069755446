import React, { useState } from 'react';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/Button';
import ContactPopup from '../../../../components/ContactPopup';
import withStyles from '@material-ui/core/styles/withStyles';
import contactStyles from './styles/contactStyles';

const Contact = ({ classes, subtitle }) => {
  const [contactFormVisible, setContactFormVisible] = useState(false);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.textContainer}>
            <Typography
              variant="h4"
              color="secondary"
              className={classes.title}
            >
              Sound Interesting? Get in Touch.
            </Typography>
            {subtitle && (
              <Typography variant="body1" color="secondary">
                {subtitle}
              </Typography>
            )}
          </div>
          <Button
            variant="secondary"
            className={classes.button}
            onClick={() => setContactFormVisible(true)}
          >
            CONTACT US
          </Button>
        </div>
      </div>
      {contactFormVisible && (
        <ContactPopup onClose={() => setContactFormVisible(false)} />
      )}
    </>
  );
};

export default withStyles(contactStyles)(Contact);
