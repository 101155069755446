import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Disclosures = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Disclosures of Personal Information</Title>
    <Description>
      We are not in the business of selling or renting your information to
      telemarketers and we do not share your personally identifiable information
      with others, except as follows: We may share information with trusted
      business partners, such as system integrators, distributors, and referral
      partners and any other party specifically identified on the page on which
      you submit your information. We engage agents and service providers who
      process data only on our behalf and for our purposes under confidentiality
      agreements. We may share your information as required by law or in the
      interest of protecting or exercising our or others’ legal rights, e.g.,
      without limitation, in connection with requests from law enforcement
      officials and in connection with court proceedings. We may share or
      transfer your information in connection with a prospective or actual sale,
      merger, transfer or other reorganization of all or parts of our business.
      Also, we reserve the right to fully use and disclose any information that
      is not in personally identifiable form (such as statistics and survey
      results that do not identify you individually by name).
    </Description>
  </Element>
);

export default Disclosures;
