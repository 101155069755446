import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import linkStyles from './styles/linkStyles';

const Link = ({ classes, children, href, router }) =>
  router ? (
    <RouterLink to={href} className={classes.root}>
      {children}
    </RouterLink>
  ) : (
    <a href={href} className={classes.root}>
      {children}
    </a>
  );

export default withStyles(linkStyles)(Link);
