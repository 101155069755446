const footerStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.ink.ink0,
    padding: theme.spacing(6, 2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
  },
  linkContainer: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      '&:not(:last-child):after': {
        content: "'|'",
        position: 'absolute',
        left: '100%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        fontFamily: "'Lato', 'Helvetica', sans-serif",
        fontWeight: 'normal',
        fontSize: '0.875rem',
        lineHeight: '140%',
        color: theme.palette.ink.ink90,
      },
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
  },
});

export default footerStyles;
