const titleStyles = (theme) => ({
  root: {
    marginBottom: theme.spacing(5),
    color: theme.palette.ink.ink0,
    fontWeight: 300,
    fontSize: '56px',
    lineHeight: '150%',
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(3),
      fontSize: '38px',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(5),
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: '130%',
      textAlign: 'left',
      marginBottom: theme.spacing(3),
    },
  },
  bold: { fontWeight: 'bold' },
});

export default titleStyles;
