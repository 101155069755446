import React from 'react';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Contact from '../components/Contact';
import Footer from '../../../components/Footer';
import Content from '../components/Content';
import Approach from './components/Approach';
import Operators from './components/Operators';
import Vendors from './components/Vendors';

const sections = [
  {
    id: 'Approach',
    title: 'A New Approach',
    component: Approach,
  },
  {
    id: 'Operators',
    title: 'Gaming Operators',
    component: Operators,
  },
  {
    id: 'Vendors',
    title: 'Gaming Vendors',
    component: Vendors,
  },
];

const SmartChip = () => (
  <>
    <Header />
    <Banner title="Smart Chip Gaming" mobileTitle="Smart Chip Gaming" />
    <Content sections={sections} />
    <Contact />
    <Footer />
  </>
);

export default SmartChip;
