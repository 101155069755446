import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '../../../../components/Typography';
import NavigationItem from './components/NavigationItem';
import navigationStyles from './styles/navigationStyles';
import isIE from '../../../../utils/isIE';

class Navigation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      intersectionStatus: props.sections.reduce(
        (obj, item) => ({
          ...obj,
          [item.id]: item,
        }),
        {},
      ),
      active: props.sections[0].id,
      firstVisible: true,
      lastVisible: true,
      scrollEventSet: false,
    };

    this.ioOptions = {
      marginRoot: '-106px 0px 0px 0px',
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    };

    this.scrollContainerRef = React.createRef();

    console.log(props.sections);
    this.linksPointerArray = props.sections.map(
      (section) => `Link${section.id}`,
    );
  }

  setIntersectionStatus = (intersectionStatus, callback) =>
    this.setState({ intersectionStatus }, callback);
  setActive = (active, callback) => this.setState({ active }, callback);
  setFirstVisible = (firstVisible, callback) =>
    this.setState({ firstVisible }, callback);
  setLastVisible = (lastVisible, callback) =>
    this.setState({ lastVisible }, callback);
  setScrollEventSet = (scrollEventSet, callback) =>
    this.setState({ scrollEventSet }, callback);

  componentDidMount() {
    const { sections } = this.props;

    /**
     * Scroll behavior related code
     */
    const observer = new IntersectionObserver(this.ioCallback, this.ioOptions);
    sections.map(({ id }) => observer.observe(document.getElementById(id)));
  }

  // TODO: Not perfect solution, this should be fired in componentDidMount but need to fix ref issue
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { scrollEventSet } = this.state;
    const scrollContainer = this.scrollContainerRef.current;

    if (!scrollEventSet && scrollContainer) {
      this.setScrollEventSet({ scrollEventSet: true }, () => {
        scrollContainer.addEventListener('scroll', this.onScroll);
        scrollContainer.dispatchEvent(new Event('scroll'));
      });
    }
  }

  componentWillUnmount() {
    const scrollContainer = this.scrollContainerRef.current;

    if (!scrollContainer) {
      return;
    }

    scrollContainer.removeEventListener('scroll', this.onScroll);

    this.observer && this.observer.disconnect();
  }

  onScroll = (event) => {
    const isFirstVisible = event.target.scrollLeft === 0;
    const isLastVisible =
      event.target.scrollLeft ===
      event.target.scrollWidth - event.target.offsetWidth;

    this.setFirstVisible(isFirstVisible);
    this.setLastVisible(isLastVisible);
  };

  getMaxIntersection = () => {
    const { intersectionStatus } = this.state;

    return Object.keys(intersectionStatus).reduce((maxSectionID, sectionID) =>
      intersectionStatus[maxSectionID] >= intersectionStatus[sectionID]
        ? maxSectionID
        : sectionID,
    );
  };

  ioCallback = (entries) => {
    const { intersectionStatus } = this.state;

    const updatedIntersectionStatus = {
      ...intersectionStatus,
    };
    entries.forEach((entry) => {
      updatedIntersectionStatus[entry.target.id] = entry.intersectionRatio;
    });

    if (!isIE()) {
      this.setIntersectionStatus(updatedIntersectionStatus, () => {
        const maxSectionKey = this.getMaxIntersection();
        this.setActive(maxSectionKey, () => {
          const scrollContainer = this.scrollContainerRef.current;

          if (scrollContainer) {
            const elementId = `Link${this.state.active}`;
            const scrollTarget = document.getElementById(elementId);
            const scrollTargetIndex = this.linksPointerArray.indexOf(elementId);
            const isFirst = scrollTargetIndex === 0;
            const isLast =
              scrollTargetIndex === this.linksPointerArray.length - 1;

            const leftOffset = isFirst || isLast ? 16 : 48;

            scrollContainer.scrollTo({
              left: scrollTarget.offsetLeft - leftOffset,
              behavior: 'smooth',
            });
          }
        });
      });
    }
  };

  render() {
    const { classes, className, sections } = this.props;
    const { active, firstVisible, lastVisible } = this.state;

    return (
      <>
        <Hidden lgUp>
          <div
            className={classNames(
              classes.root,
              {
                [classes.fadeLeft]: !firstVisible,
                [classes.fadeRight]: !lastVisible,
              },
              className,
            )}
            id="infoNavigationRoot"
          >
            <div className={classes.container} ref={this.scrollContainerRef}>
              <Grid container spacing={4} wrap="nowrap">
                {sections.map(({ id, title }) => (
                  <Grid item xs="auto" id={`Link${id}`} key={id}>
                    <NavigationItem active={id === active} id={id}>
                      {title}
                    </NavigationItem>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </Hidden>
        <Hidden mdDown>
          <div
            className={classNames(classes.root, className)}
            id="infoNavigationRoot"
          >
            <div className={classes.container}>
              <Typography variant="h5" className={classes.title}>
                Jump to a Section:
              </Typography>
            </div>
            {sections.map(({ id, title }) => (
              <NavigationItem active={id === active} id={id} key={id}>
                {title}
              </NavigationItem>
            ))}
          </div>
        </Hidden>
      </>
    );
  }
}

export default withStyles(navigationStyles)(Navigation);
