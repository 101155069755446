import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Purposes = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Collection Purposes, Use of Data</Title>
    <Description>
      We collect passively tracked data primarily for purposes of administering,
      protecting and improving our Websites and our systems, to better
      understand the preferences of our Website visitors, to identify server
      problems, to compile aggregated statistics about site usage, and to help
      personalize your experience on our Websites.
    </Description>
    <Description>
      We collect actively submitted data primarily for two purposes: Firstly,
      for the purpose for which you originally sent us the data (e.g., to
      provide information to you or sign you up for the use of our services);
      and secondly, for purposes of contacting you to further discuss your
      interest in our company, the services we provide and ways we can improve
      them to provide information on services, promotions and events. You can
      opt out of receiving marketing emails as explained below.
    </Description>
  </Element>
);

export default Purposes;
