import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import {
  animated,
  Spring,
} from '@react-spring/web/dist/react-spring-web.cjs.prod.js';
import NavigationItem from './components';
import navigationStyles from './styles/navigationStyles';
import Portal from '../../../Portal';

const navigationItems = [
  {
    id: 'home',
    title: 'Home',
  },
  {
    id: 'scratch',
    title: 'SmartScratch',
  },
  {
    id: 'chip',
    title: 'Smart Chip Gaming',
  },
  {
    id: 'contact',
    title: 'Contact Us',
  },
];

const MenuButton = ({ classes, open, onClick }) => (
  <div className={classes.menuButton} onClick={onClick}>
    <div className={classes.menuButtonContainer}>
      <Spring
        items={open}
        from={{
          top: '0%',
          rotate: '0deg',
        }}
        to={{
          top: open ? '50%' : '0%',
          rotate: open ? '-45deg' : '0deg',
        }}
      >
        {(style) => (
          <animated.div style={style} className={classes.menuButtonItem} />
        )}
      </Spring>
      <Spring
        items={open}
        from={{
          top: '50%',
          x: 0,
          opacity: 1,
        }}
        to={{
          top: '50%',
          x: open ? 30 : 0,
          opacity: open ? 0 : 1,
        }}
      >
        {(style) => (
          <animated.div style={style} className={classes.menuButtonItem} />
        )}
      </Spring>
      <Spring
        items={open}
        from={{
          top: '100%',
          rotate: '0deg',
        }}
        to={{
          top: open ? '50%' : '100%',
          rotate: open ? '45deg' : '0deg',
        }}
      >
        {(style) => (
          <animated.div style={style} className={classes.menuButtonItem} />
        )}
      </Spring>
    </div>
  </div>
);

class Navigation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      intersectionStatus: {
        home: 0,
        scratch: 0,
        chip: 0,
        contact: 0,
      },
      active: null,
      visible: false,
    };

    this.ioOptions = {
      marginRoot: '-106px 0px 0px 0px',
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    };
    this.observer = null;
  }

  setIntersectionStatus = (intersectionStatus, callback) =>
    this.setState({ intersectionStatus }, callback);
  setActive = (active, callback) => this.setState({ active }, callback);
  setVisible = (visible, callback) => this.setState({ visible }, callback);

  componentDidMount() {
    this.observer = new IntersectionObserver(this.ioCallback, this.ioOptions);
    navigationItems.map(({ id }) =>
      this.observer.observe(document.getElementById(id)),
    );
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  getMaxIntersection = () => {
    const { intersectionStatus } = this.state;

    return Object.keys(intersectionStatus).reduce((maxSectionID, sectionID) =>
      intersectionStatus[maxSectionID] > intersectionStatus[sectionID]
        ? maxSectionID
        : sectionID,
    );
  };

  ioCallback = (entries) => {
    const { intersectionStatus } = this.state;

    const updatedIntersectionStatus = {
      ...intersectionStatus,
    };
    entries.forEach((entry) => {
      updatedIntersectionStatus[entry.target.id] = entry.intersectionRatio;
    });

    this.setIntersectionStatus(updatedIntersectionStatus, () => {
      const maxSectionKey = this.getMaxIntersection();
      this.setActive(maxSectionKey);
    });
  };

  render() {
    const { classes } = this.props;
    const { visible, active } = this.state;

    return (
      <>
        <Hidden mdUp>
          <MenuButton
            classes={classes}
            open={visible}
            onClick={() => this.setVisible(!visible)}
          />
          {visible && (
            <Portal>
              <div className={classes.root}>
                {navigationItems.map(({ id, title }) => (
                  <NavigationItem
                    active={id === active}
                    id={id}
                    key={id}
                    onClick={() => this.setVisible(false)}
                  >
                    {title}
                  </NavigationItem>
                ))}
              </div>
            </Portal>
          )}
        </Hidden>
        <Hidden smDown>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justify="space-between"
          >
            {navigationItems.map(({ id, title }) => (
              <Grid item xs="auto" key={id}>
                <NavigationItem active={id === active} id={id}>
                  {title}
                </NavigationItem>
              </Grid>
            ))}
          </Grid>
        </Hidden>
      </>
    );
  }
}
export default withStyles(navigationStyles)(Navigation);
