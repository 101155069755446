import React from 'react';
import Typography from '../../../../components/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import descriptionStyles from './styles/descriptionStyles';

const Description = ({ classes, children }) => (
  <Typography variant="body2" className={classes.root}>
    {children}
  </Typography>
);

export default withStyles(descriptionStyles)(Description);
