import React from 'react';
import Typography from '../../../../../../components/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import subTitleStyles from './styles/subTitleStyles';

const SubTitle = ({ classes }) => (
  <>
    <Typography variant="body1" className={classes.root}>
      Smart cards are trusted worldwide as the secure, reliable technology
      foundation for payments, public transit, telecommunications, IT security,
      and more.
    </Typography>
    <Typography variant="body1" className={classes.root}>
      Our new platform for regulated gaming harnesses the power of smart cards
      with mobile to deliver unique gaming solutions that allow you to reach
      more players and increase revenues in ways never thought possible.
    </Typography>
  </>
);

export default withStyles(subTitleStyles)(SubTitle);
