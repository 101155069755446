import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as RevenueIcon } from './assets/images/revenue.svg';
import { ReactComponent as MobileGameIcon } from './assets/images/mobile_game.svg';
import { ReactComponent as RetailIcon } from './assets/images/retail.svg';
import { ReactComponent as SlotMachineIcon } from './assets/images/slot_machine.svg';
import { ReactComponent as SecurityIcon } from './assets/images/security.svg';
import { ReactComponent as MarketIcon } from './assets/images/market.svg';
import Feature from '../../../../../../components/Feature';
import featuresStyles from './styles/featuresStyles';

const Features = ({ classes }) => (
  <Grid container spacing={4} className={classes.root}>
    <Grid item sm={6} md={4} className={classes.feature}>
      <Feature icon={RevenueIcon}>
        Increase revenue and attract new players.
      </Feature>
    </Grid>
    <Grid item sm={6} md={4} className={classes.feature}>
      <Feature icon={MobileGameIcon}>
        Mobile game experiences - no player registration required.
      </Feature>
    </Grid>
    <Grid item sm={6} md={4} className={classes.feature}>
      <Feature icon={RetailIcon}>
        Expand sales to multi-lane retail environments at no cost.
      </Feature>
    </Grid>
    <Grid item sm={6} md={4} className={classes.feature}>
      <Feature icon={SlotMachineIcon}>
        Simple integration with existing game terminals and systems.
      </Feature>
    </Grid>
    <Grid item sm={6} md={4} className={classes.feature}>
      <Feature icon={SecurityIcon}>
        Put an end to security concerns with ticket theft and fraud.
      </Feature>
    </Grid>
    <Grid item sm={6} md={4} className={classes.feature}>
      <Feature icon={MarketIcon}>
        Markets with limited retail? No problem. We support you too.
      </Feature>
    </Grid>
  </Grid>
);

export default withStyles(featuresStyles)(Features);
