import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import SupTitle from './components/SupTitle';
import Title from './components/Title';
import SubTitle from './components/SubTitle';
import Animation from './components/Animation';
import Container from '../../../../components/Container';
import bannerStyles from './styles/bannerStyles';

const Banner = ({ classes }) => (
  <div className={classes.root}>
    <Container>
      <Hidden lgUp>
        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={12} sm={6} md={7} container alignItems="center">
            <Grid item xs={12}>
              <SupTitle />
              <Title />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Animation />
          </Grid>
          <Grid item xs={12}>
            <SubTitle />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdDown>
        <Grid container spacing={4} alignItems="stretch">
          <Grid item md={6} container alignItems="center">
            <Grid item xs={12}>
              <SupTitle />
              <Title />
              <SubTitle />
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Animation />
          </Grid>
        </Grid>
      </Hidden>
    </Container>
  </div>
);

export default withStyles(bannerStyles)(Banner);
