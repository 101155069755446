import React, { useState } from 'react';
import { Element } from 'react-scroll';
import withStyles from '@material-ui/core/styles/withStyles';
import Operator from '../../assets/images/operator.jpg';
import Placement from '../../assets/images/placement.jpg';
import Description from '../../../components/Description';
import Title from '../../../components/Title';
import Image from '../../../components/Image';
import SubTitle from '../../../components/SubTitle';
import ContactPopup from '../../../../../components/ContactPopup';
import operatorStyles from './styles/operatorStyles';

const Operators = ({ id, classes }) => {
  const [contactFormVisible, setContactFormVisible] = useState(false);

  return (
    <Element name={id} id={id}>
      <Title>For Gaming Operators</Title>
      <SubTitle>
        No expensive, time-consuming IT projects or infrastructure updates
        required.
      </SubTitle>
      <Description>
        With the Smart Chip Gaming system, what you see is what you get. For the
        player, a tablet and case plus a card is all they need to game. For the
        operator, a box of tablets, a box of cards, and a cage device for
        cashing the player in and out of a card is all that is needed to operate
        the system. And putting it on the floor is as simple as powering
        everything up.
      </Description>
      <Description>
        There are no servers to deploy and no wireless networks to upgrade
        because Smart Chip Gaming does not use wireless networking for gaming.
        The gaming is completely contained within the card and tablet. This
        means operators can offer gaming convenience to players in ways never
        thought possible before. Features for restricting gaming to specific
        physical areas and online monitoring to meet regulatory requirements are
        also built-in to the system all at no additional cost.
      </Description>
      <Image src={Operator} alt="Operator" />
      <SubTitle>
        The end to hard decisions regarding gaming machine acquisition and
        placement.
      </SubTitle>
      <Description>
        With the simplicity, security, and low cost of the Smart Chip Gaming
        solution, now you can afford to offer gaming anywhere on-property.
        In-room gaming is as easy to offer as poolside or lounge gaming. With
        the player’s funds on the card and the tablet in hand, they can take
        gaming anywhere you allow. And when they want to take a break,
        preventing others from gaming is as simple as removing the card and
        storing it in a wallet or purse. When it's time to cash out and get
        rewarded for play, a return visit to the service desk to return the
        tablet and cash out funds from the card is all that is required.
      </Description>
      <Image src={Placement} alt="Placement" />
      <SubTitle>A gaming system customized to your players’ needs.</SubTitle>
      <Description>
        Game selection, RTPs, and betting options are all set within each card.
        That means that offering a tablet to a player who enjoys low stakes
        gaming vs. a high limit VIP is as simple as choosing the card with the
        desired configuration for use with the tablet. Player loyalty programs
        are also fully supported. All gaming that takes place in a card can be
        linked to a player's loyalty account resulting in a complete record of
        all gameplay for any casino management system.
      </Description>
      <SubTitle>Sound interesting? Get in touch.</SubTitle>
      <Description>
        Smart Chip Gaming is the first and only gaming solution of its type with
        applications in gaming markets worldwide. If you are interested in
        offering Smart Chip Gaming to your players or partnering to deliver a
        custom solution, we want to hear from you. Drop us an email at:{' '}
        <span
          className={classes.link}
          onClick={() => setContactFormVisible(true)}
        >
          info@tapcentive.com
        </span>
      </Description>
      {contactFormVisible && (
        <ContactPopup onClose={() => setContactFormVisible(false)} />
      )}
    </Element>
  );
};

export default withStyles(operatorStyles)(Operators);
