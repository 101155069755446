import React from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '../../components/Typography';
import Header from './components/Header';
import Footer from '../../components/Footer';
import notFoundStyles from './styles/notFoundStyles';

const NotFound = ({ classes }) => (
  <div className={classes.root}>
    <Header />
    <Grid
      container={2}
      alignItems="center"
      justify="space-around"
      className={classes.container}
    >
      <Grid xs={12}>
        <Typography className={classes.title} align="center">
          404
        </Typography>
        <Typography className={classes.subTitle} align="center" variant="h3">
          Page not found
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography className={classes.description} align="center">
          The page is not available on our website.
        </Typography>
        <Typography align="center" variant="button" component="p">
          <Link to="/" className={classes.link}>
            Return to the Homepage
          </Link>
        </Typography>
      </Grid>
    </Grid>
    <Footer />
  </div>
);

export default withStyles(notFoundStyles)(NotFound);
