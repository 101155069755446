import React, { useState } from 'react';
import SmartChipGamingPresentationVideo from '../../assets/videos/smart_chip_gaming_presentation.mp4';
import SmartChipGamingPresentationPreview from '../../assets/images/smart_chip_gaming_presentation_preview.png';
import Overlay from '../../../../../../components/Overlay';
import VideoPlayer from '../../../../../../components/VideoPlayer';
import VideoPreview from '../../../../../../components/VideoPreview';
import withStyles from '@material-ui/core/styles/withStyles';
import videoStyles from './styles/videoStyles';

const Video = ({ classes }) => {
  const [videoOverlay, setVideoOverlay] = useState(false);
  return (
    <>
      <VideoPreview
        className={classes.videoPreview}
        image={SmartChipGamingPresentationPreview}
        onClick={() => setVideoOverlay(true)}
      />
      <Overlay open={videoOverlay} onClose={() => setVideoOverlay(false)}>
        <VideoPlayer
          controls
          sources={[
            {
              src: SmartChipGamingPresentationVideo,
              type: 'video/mp4',
            },
          ]}
          poster={SmartChipGamingPresentationPreview}
          fluid
        />
      </Overlay>
    </>
  );
};

export default withStyles(videoStyles)(Video);
