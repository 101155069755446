import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Changes = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Changes to the Policy</Title>
    <Description>
      We may revise and update this from Policy time to time in our sole
      discretion. All changes are effective immediately when we post them and
      apply to all access to and use of the Websites thereafter.
    </Description>
    <Description>
      Your continued use of the Websites following the posting of revised Policy
      means that you accept and agree to the changes. You may wish to check this
      page from time to time so you are aware of any changes, as they are
      binding on you.
    </Description>
  </Element>
);

export default Changes;
