import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Uses = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Prohibited Uses</Title>
    <Description>
      You may use these Websites only for lawful purposes and in accordance with
      these Terms. You agree not to use these Websites to violate federal,
      state, local or international laws or regulations or to harm others. You
      also agree not to use these Websites in such a way that would disable,
      overburden, damage or impair the sites, access, tamper with, or use
      non-public areas or parts of the sites, or probe, scan, or test the
      vulnerability of the sites or network.
    </Description>
  </Element>
);

export default Uses;
