import React from 'react';
import Typography from '../../../../../../components/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import supTitleStyles from './styles/supTitleStyles';

const SupTitle = ({ classes }) => (
  <Typography variant="label" className={classes.root}>
    TAPCENTIVE INC.
  </Typography>
);

export default withStyles(supTitleStyles)(SupTitle);
