import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import linksStyles from './styles/linksStyles';

const Links = ({ classes, onClick }) => (
  <Grid container spacing={4}>
    <Grid item xs={12} md="auto" className={classes.linkContainer}>
      <div className={classes.link} onClick={onClick}>
        info@tapcentive.com
      </div>
    </Grid>
  </Grid>
);

export default withStyles(linksStyles)(Links);
