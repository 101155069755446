import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Accessing = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Accessing these Websites</Title>
    <Description>
      We reserve the right to withdraw or amend these Websites, and any service
      or material we provide on these Websites, in our sole discretion without
      notice. We will not be liable if for any reason all or any part of these
      Websites is unavailable at any time or for any period.
    </Description>
  </Element>
);

export default Accessing;
