import isIE from '../../../../../../utils/isIE';

const navigationItemStyles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    padding: '25px 0',
    [theme.breakpoints.down('md')]: {
      padding: '21px 0',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      height: theme.spacing(7),
      padding: theme.spacing(0, 2),
      borderBottom: `1px ${theme.palette.ink.ink20} solid`,
      '&:first-child': { borderTop: `1px ${theme.palette.ink.ink20} solid` },
    },
  },
  active: {
    boxShadow: 'inset 0px -5px 0px -1px #ec5341',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      '&:after': {
        content: "''",
        position: 'absolute',
        top: '0',
        left: '0',
        width: '4px',
        height: '100%',
        backgroundColor: theme.palette.red.red60,
      },
    },
  },
  text: {
    transition: 'text-shadow .3s ease-in-out',
  },
  textActive: {
    textShadow: isIE() ? 'none' : '0 0 .65px #000, 0 0 .65px #000',
    [theme.breakpoints.down('sm')]: {},
  },
});

export default navigationItemStyles;
