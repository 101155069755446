import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Security = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Technology That Protects Your Players and Revenue.</Title>
    <Description>
      SmartScratch has been designed from the ground-up using bank-grade
      technology and security that powers global banking and payment systems
      worldwide. Unlike paper game tickets that need to be carefully protected
      in secure dispensers, SmartScratch cards hold no value until they are
      purchased. This means the cards can be offered on self-service displays
      for easy purchase and retailers don't need to worry about the theft of
      unsold cards.
    </Description>
    <Description>
      This level of security also gives players confidence in the game because
      their wins are secured by the card. And with their own phone they can
      confirm the authenticity of their card. The card security also means
      operators don't have to spend precious resources monitoring vendors and
      distributors for untrusted insiders who attempt to steal high prize
      tickets or introduce counterfeit tickets as a means to steal from players
      and operators.
    </Description>
  </Element>
);

export default Security;
