const videoPreviewStyles = (theme) => ({
  root: {
    position: 'relative',
    cursor: 'pointer',
    '& img': {
      display: 'block',
      width: '100%',
    },
    '&:hover $play': {
      opacity: 0.8,
    },
  },
  play: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'opacity 0.3s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
});

export default videoPreviewStyles;
