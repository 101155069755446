import React from 'react';
import SmartScratchVideoPreview from '../../assets/images/smartscratch_video_preview.png';
import VideoPreview from '../../../../../../components/VideoPreview';
import withStyles from '@material-ui/core/styles/withStyles';
import videoStyles from './styles/videoStyles';

const Video = ({ classes, onClick }) => (
  <VideoPreview
    className={classes.videoPreview}
    image={SmartScratchVideoPreview}
    onClick={onClick}
  />
);

export default withStyles(videoStyles)(Video);
