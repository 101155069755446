import Pattern from '../assets/images/pattern.jpg';

const bannerStyles = (theme) => ({
  root: { background: `url("${Pattern}")` },
  container: {
    maxWidth: '1160px',
    margin: '0 auto',
    padding: theme.spacing(15, 4),
    '@supports (padding: max(0px))': {
      paddingLeft: `max(${theme.spacing(4)}px, env(safe-area-inset-left))`,
      paddingRight: `max(${theme.spacing(4)}px, env(safe-area-inset-right))`,
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(8, 4),
      '@supports (padding: max(0px))': {
        paddingLeft: `max(${theme.spacing(4)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(4)}px, env(safe-area-inset-right))`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
      '@supports (padding: max(0px))': {
        paddingLeft: `max(${theme.spacing(2)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(2)}px, env(safe-area-inset-right))`,
      },
    },
  },
});

export default bannerStyles;
