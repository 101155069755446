const logoStyles = (theme) => ({
  root: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
    },
  },
  image: {
    display: 'block',
    height: theme.spacing(7),
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
});

export default logoStyles;
