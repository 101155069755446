import React from 'react';
import { Link } from 'react-router-dom';
import BaseButton from '../../../../../../components/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonStyles from './styles/buttonStyles';

const Button = ({ classes, children }) => (
  <div className={classes.root}>
    <Link to="/smartscratch">
      <BaseButton className={classes.button}>{children}</BaseButton>
    </Link>
  </div>
);

export default withStyles(buttonStyles)(Button);
