import { createMuiTheme } from '@material-ui/core/styles';
const spacing = 8;

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1600,
    },
  },
  variables: {
    zIndex: {
      navigation: 1,
      navigationFade: 2,
      header: 3,
      contactPopup: 4,
      video: 5,
    },
  },
  spacing,
  palette: {
    type: 'light',
    // contrastThreshold: 2,
    tonalOffset: 0.2,
    ink: {
      ink100: '#000000',
      ink90: '#0f1114',
      ink80: '#20242a',
      ink70: '#41454a',
      ink60: '#626569',
      ink50: '#85878a',
      ink40: '#a6a7aa',
      ink30: '#bcbdbf',
      ink20: '#d2d3d4',
      ink10: '#e8e9e9',
      ink5: '#f7f7f7',
      ink0: '#ffffff',
    },
    red: {
      red90: '#660a00',
      red80: '#8d180b',
      red70: '#cb3525',
      red60: '#ec5341',
      red50: '#ff6a59',
      red40: '#ff7f71',
      red30: '#ff9589',
      red20: '#ffa89e',
      red10: '#ffc5bf',
      red5: '#ffe2df',
    },
    yellow: {
      yellow90: '#4d3900',
      yellow80: '#8c6900',
      yellow70: '#cc9900',
      yellow60: '#f2c744',
      yellow50: '#ffcc33',
      yellow40: '#ffd24d',
      yellow30: '#ffe189',
      yellow20: '#ffedb8',
      yellow10: '#fff5d9',
      yellow5: '#fffbf0',
    },
    green: {
      green90: '#2e4d00',
      green80: '#548c00',
      green70: '#7acc00',
      green60: '#9eeb2a',
      green50: '#abe556',
      green40: '#b8ff4d',
      green30: '#d6ff99',
      green20: '#e6ffbf',
      green10: '#f0ffd9',
      green5: '#f9fff0',
    },
  },
  overrides: {
    // MuiListItem: {
    //   root: {
    //     paddingTop: 1.5 * spacing,
    //     paddingBottom: 1.5 * spacing,
    //   },
    // },
  },
});

export default theme;
