const buttonBlockStyles = (theme) => ({
  root: {
    textAlign: 'center',
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
  },
});

export default buttonBlockStyles;
