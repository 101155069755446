import React from 'react';
import { Element } from 'react-scroll';
import Games from '../../assets/images/games.jpg';
import Worldwide from '../../assets/images/worldwide.jpg';
import Locations from '../../assets/images/locations.jpg';
import Description from '../../../components/Description';
import Title from '../../../components/Title';
import Image from '../../../components/Image';
import SubTitle from '../../../components/SubTitle';

const Vendors = ({ id }) => (
  <Element name={id} id={id}>
    <Title>For Gaming Vendors</Title>
    <SubTitle>Expand your offerings with our system and your games.</SubTitle>
    <Description>
      The Smart Chip Gaming system offers the lowest cost, simplest way to offer
      a reliable, secure portable gaming solution in any gaming environment. If
      you have HTML5 games you have created or licensed and want to put them in
      the hands of players in any gaming environment, we can make it happen.
      We're actively seeking distribution partnerships with gaming vendors who
      are looking for growth but have been constrained by traditional gaming
      system hardware.
    </Description>
    <Image src={Games} alt="Games" />
    <SubTitle>
      Manufacture and distribute locally in any market worldwide.
    </SubTitle>
    <Description>
      The unique hardware model of the Smart Chip Gaming platform enables us to
      arrange for tablet purchase and card production in any market worldwide.
      That means no shipping costs or tariffs associated with traditional models
      of building centrally and shipping internationally. Repair and replacement
      of the tablets can also be handled locally as well. And with Tapcentive's
      bank grade card security model, the risk of machine tampering or fraud
      that is associated with traditional gaming systems is eliminated.
    </Description>
    <Image src={Worldwide} alt="Worldwide" />
    <SubTitle>Rethink what's possible in gaming operations.</SubTitle>
    <Description>
      Using the Smart Chip Gaming system to expand gaming at casinos and resorts
      is an obvious opportunity, but it is just the beginning: cruise ships,
      bars and taverns, and bingo halls, are all easily within reach wherever
      gaming regulations allow. While no one has yet succeeded in delivering a
      solution to airplane gambling, our system functions securely and reliably
      without touching any onboard systems or communications. If there is an
      operator with interest, Smart Chip Gaming is the way to make it happen.
    </Description>
    <Image src={Locations} alt="Locations" />
  </Element>
);

export default Vendors;
