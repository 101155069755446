import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '../../../../../../components/Typography';
import subTitleStyles from './styles/subTitleStyles';

const SubTitle = ({ classes }) => (
  <Grid container spacing={2} className={classes.root}>
    <Grid item xs={12}>
      <Typography variant="body2" className={classes.text}>
        Now we're bringing our expertise and new ideas to the gaming industry.
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2" className={classes.text}>
        To learn more about our products, discuss a partnership, or explore how
        your games can run on our platform, please get in touch. We'd love to
        hear from you.
      </Typography>
    </Grid>
  </Grid>
);

export default withStyles(subTitleStyles)(SubTitle);
