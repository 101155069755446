import React from 'react';
import { Element } from 'react-scroll';
import withStyles from '@material-ui/core/styles/withStyles';
import Banner from './components/Banner';
import About from './components/About';
import SmartScratch from './components/SmartScratch';
import SmartChipGaming from './components/SmartChipGaming';
import Contact from './components/Contact';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import homeStyles from './styles/homeStyles';

const Home = ({ classes }) => (
  <>
    <Header />
    <div className={classes.root}>
      <Element name="home" id="home">
        <Banner />
      </Element>
      <Element name="scratch" id="scratch">
        <About />
        <SmartScratch />
      </Element>
      <Element name="chip" id="chip">
        <SmartChipGaming />
      </Element>
      <Element name="contact" id="contact">
        <Contact />
      </Element>
    </div>
    <Footer />
  </>
);

export default withStyles(homeStyles)(Home);
