import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '../../../../../../../../../components/Typography';
import featureStyles from './styles/featureStyles';

const Feature = ({ classes, children, title, icon: Icon }) => (
  <Grid container spacing={1} alignItems="center">
    <Grid item xs={12} className={classes.title}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs="auto">
          <Icon className={classes.icon} />
        </Grid>
        <Grid item xs="auto">
          <Typography variant="h6">{title}</Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2">{children}</Typography>
    </Grid>
  </Grid>
);

export default withStyles(featureStyles)(Feature);
