const iPhoneStyles = (theme) => ({
  root: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    width: '85%',
    transform: 'translate(35%, 45%)',
    [theme.breakpoints.down('md')]: {
      width: '130%',
      transform: 'translate(30%, 0%)',
    },
  },
});

export default iPhoneStyles;
