import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as SlotMachineIcon } from './assets/images/slot_machine.svg';
import { ReactComponent as BoxIcon } from './assets/images/box.svg';
import { ReactComponent as GamingFastIcon } from './assets/images/gaming_fast.svg';
import { ReactComponent as TabletsIcon } from './assets/images/tablets.svg';
import { ReactComponent as LoyaltyCardIcon } from './assets/images/loyalty_card.svg';
import { ReactComponent as GLIIcon } from './assets/images/gli.svg';
import Feature from '../../../../../../components/Feature';
import featuresStyles from './styles/featuresStyles';

const Features = ({ classes }) => (
  <Grid container spacing={4} className={classes.root}>
    <Grid item sm={6} md={4} className={classes.feature}>
      <Feature icon={SlotMachineIcon}>
        Let players gamble when and where they choose.
      </Feature>
    </Grid>
    <Grid item sm={6} md={4} className={classes.feature}>
      <Feature icon={BoxIcon}>
        Installation is as simple as opening boxes and charging tablets.
      </Feature>
    </Grid>
    <Grid item sm={6} md={4} className={classes.feature}>
      <Feature icon={GamingFastIcon}>
        Gaming is as fast and reliable as any machine on a gaming floor.
      </Feature>
    </Grid>
    <Grid item sm={6} md={4} className={classes.feature}>
      <Feature icon={TabletsIcon}>
        Simple, low cost operation of 10's, 100's or 1000's of tablets.
      </Feature>
    </Grid>
    <Grid item sm={6} md={4} className={classes.feature}>
      <Feature icon={LoyaltyCardIcon}>
        Compatible with casino management and loyalty systems.
      </Feature>
    </Grid>
    <Grid item sm={6} md={4} className={classes.feature}>
      <Feature icon={GLIIcon}>
        A GLI-11 certified gaming device — not wireless gaming.
      </Feature>
    </Grid>
  </Grid>
);

export default withStyles(featuresStyles)(Features);
