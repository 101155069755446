import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Waiver = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Waiver, Severability & Assignment</Title>
    <Description>
      Tapcentive's failure to enforce a provision of these Terms is not a waiver
      of its right to do so later. If a provision is found unenforceable, the
      remaining provisions of the Terms will remain in full effect and an
      enforceable term will be substituted reflecting our intent as closely as
      possible.
    </Description>
    <Description>
      You may not assign any of your rights under these Terms, and any such
      attempt will be void. Tapcentive may assign its rights to any of its
      affiliates or subsidiaries, or to any successor in interest of any
      business associated with the Services.
    </Description>
  </Element>
);

export default Waiver;
