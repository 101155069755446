import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Access = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Access</Title>
    <Description>
      If personal information you have submitted through the Websites is no
      longer accurate, current, or complete, and you wish to update it, please
      send an email to support@tapcentive.com. Upon appropriate request we will
      usually be glad to update or amend your information, but we reserve the
      right to use information obtained previously to verify your identity or
      take other actions that we believe are appropriate.
    </Description>
  </Element>
);

export default Access;
