import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import containerStyles from './styles/containerStyles';

const Container = ({ classes, children, className }) => (
  <div className={classNames(classes.root, className)}>{children}</div>
);

export default withStyles(containerStyles)(Container);
