import React from 'react';
import SupTitle from './components/SupTitle';
import Title from './components/Title';
import SubTitle from './components/SubTitle';
import Features from './components/Features';
import Container from '../../../../components/Container';

const About = () => (
  <Container>
    <SupTitle />
    <Title />
    <Features />
    <SubTitle />
  </Container>
);

export default About;
