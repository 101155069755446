const tabletPrivacyPAStyles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100vh',
    paddingTop: theme.spacing(9),
    paddingBottom: 0,
  },
  content: {
    width: '100%',
    flexGrow: 1,
    flexShrink: 0,
  },
  listHeader: {
    margin: theme.spacing(1, 0, 0),
  },
  list: {
    margin: theme.spacing(1, 0, 2),
  },
});

export default tabletPrivacyPAStyles;
