const imageStyles = (theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('lg')]: { marginBottom: theme.spacing(4) },
    [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(3) },
  },
});

export default imageStyles;
