import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '../../../../components/Typography';
import footerStyles from './styles/footerStyles';

const Footer = ({ classes }) => (
  <div className={classes.root}>
    <Typography>© {new Date().getFullYear()} Tapcentive, Inc.</Typography>
  </div>
);

export default withStyles(footerStyles)(Footer);
