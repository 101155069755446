const separatorStyles = (theme) => ({
  root: {
    height: '2px',
    background: theme.palette.ink.ink5,
    margin: theme.spacing(5, 0),
    [theme.breakpoints.down('lg')]: { margin: theme.spacing(4, 0) },
  },
});

export default separatorStyles;
