import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '../../../../components/Typography';
import descriptionStyles from './styles/descriptionStyles';

const Description = ({ classes, title, subtitle }) => (
  <div className={classes.root}>
    <Typography variant="h5" className={classes.title}>
      {title}
    </Typography>
    <Typography className={classes.subtitle}>{subtitle}</Typography>
  </div>
);

export default withStyles(descriptionStyles)(Description);
