const typographyStyles = (theme) => ({
  root: { margin: '0' },
  h1: {
    fontFamily: "'Montserrat', 'Helvetica', sans-serif",
    fontWeight: 'bold',
    fontSize: '64px',
    lineHeight: '110%',
    [theme.breakpoints.down('lg')]: { fontSize: '48px' },
    [theme.breakpoints.down('sm')]: { fontSize: '40px' },
  },
  h2: {
    fontFamily: "'Montserrat', 'Helvetica', sans-serif",
    fontWeight: 'bold',
    fontSize: '56px',
    lineHeight: '150%',
    [theme.breakpoints.down('lg')]: { fontSize: '38px' },
    [theme.breakpoints.down('sm')]: { fontSize: '32px' },
  },
  h3: {
    fontFamily: "'Montserrat', 'Helvetica', sans-serif",
    fontWeight: 'bold',
    fontSize: '40px',
    lineHeight: '140%',
    [theme.breakpoints.down('lg')]: { fontSize: '32px' },
    [theme.breakpoints.down('sm')]: { fontSize: '24px' },
  },
  h4: {
    fontFamily: "'Montserrat', 'Helvetica', sans-serif",
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '120%',
    [theme.breakpoints.down('lg')]: { fontSize: '30px' },
    [theme.breakpoints.down('sm')]: { fontSize: '24px' },
  },
  h5: {
    fontFamily: "'Montserrat', 'Helvetica', sans-serif",
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '110%',
    [theme.breakpoints.down('lg')]: { fontSize: '21px' },
    [theme.breakpoints.down('sm')]: { fontSize: '19px' },
  },
  h6: {
    fontFamily: "'Montserrat', 'Helvetica', sans-serif",
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '140%',
    [theme.breakpoints.down('lg')]: { fontSize: '17px' },
    [theme.breakpoints.down('sm')]: { fontSize: '17px' },
  },
  body1: {
    fontFamily: "'Lato', 'Helvetica', sans-serif",
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '156%',
    [theme.breakpoints.down('sm')]: { fontSize: '16px', lineHeight: '150%' },
  },
  body2: {
    fontFamily: "'Lato', 'Helvetica', sans-serif",
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '156%',
    [theme.breakpoints.down('sm')]: { fontSize: '14px', lineHeight: '150%' },
  },
  body3: {
    fontFamily: "'Lato', 'Helvetica', sans-serif",
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '140%',
    [theme.breakpoints.down('lg')]: { fontSize: '13px', lineHeight: '140%' },
  },
  button: {
    fontFamily: "'Lato', 'Helvetica', sans-serif",
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '140%',
    [theme.breakpoints.down('lg')]: { fontSize: '14px' },
  },
  label: {
    fontFamily: "'Lato', 'Helvetica', sans-serif",
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '140%',
    [theme.breakpoints.down('sm')]: { fontSize: '12px' },
  },
  navigation: {
    fontFamily: "'Montserrat', 'Helvetica', sans-serif",
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '140%',
    [theme.breakpoints.down('lg')]: { fontSize: '16px', lineHeight: '140%' },
    [theme.breakpoints.down('sm')]: { fontSize: '12px', lineHeight: '140%' },
  },
  colorInherit: { color: 'inherit' },
  colorPrimary: { color: theme.palette.ink.ink100 },
  colorSecondary: { color: theme.palette.ink.ink5 },
  gutterBottom: { marginBottom: theme.spacing(1) },
  alignLeft: { textAlign: 'left' },
  alignCenter: { textAlign: 'center' },
  alignRight: { textAlign: 'right' },
  alignJustify: { textAlign: 'justify' },
  displayBlock: { display: 'block' },
  displayInline: { display: 'inline' },
  weightLight: { fontWeight: 500 },
});

export default typographyStyles;
