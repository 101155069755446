import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '../../../../components/Typography';
import titleStyles from './styles/titleStyles';

const Title = ({ classes, children }) => (
  <Typography variant="h3" weight="light" className={classes.root}>
    {children}
  </Typography>
);

export default withStyles(titleStyles)(Title);
