import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Navigation from '../Navigation';
import Separator from '../Separator';
import Hidden from '@material-ui/core/Hidden';
import contentStyles from './styles/contentStyles';

const Content = ({ classes, children, sections }) => (
  <>
    <Hidden lgUp>
      <Navigation className={classes.navigation} sections={sections} />
      <div className={classes.root}>
        {sections.map(({ id, component: Component }, index) => (
          <React.Fragment key={id}>
            <Component id={id} />
            {index !== sections.length - 1 && <Separator />}
          </React.Fragment>
        ))}
        {children}
      </div>
    </Hidden>
    <Hidden mdDown>
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs="auto">
            <Navigation className={classes.navigation} sections={sections} />
          </Grid>
          <Grid item xs>
            {sections.map(({ id, component: Component }, index) => (
              <React.Fragment key={id}>
                <Component id={id} />
                {index !== sections.length - 1 && <Separator />}
              </React.Fragment>
            ))}
            {children}
          </Grid>
        </Grid>
      </div>
    </Hidden>
  </>
);

export default withStyles(contentStyles)(Content);
