import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';
import Link from '../../../components/Link';

const Acceptance = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Acceptance of the Terms</Title>
    <Description>
      Thanks for visiting the websites of Tapcentive, Inc. ("Tapcentive", "we"
      or "us"). The following terms and conditions (the “Terms") govern your use
      of our sites, including any content, functionality and services offered on
      or through tapcentive.com and smartscratch.com and related sites (the
      "Websites”).
    </Description>
    <Description>
      Please read the Terms carefully before you start to use these Websites. By
      using these Websites you accept and agree to be bound and abide by these
      Terms, as well as our Privacy Policy, found at{' '}
      <Link href="/privacy-policy" router>
        {window.location.hostname}/privacy-policy
      </Link>
      . If you do not want to agree to these Terms or the Privacy Policy, you
      must not access or use these Websites.
    </Description>
  </Element>
);

export default Acceptance;
