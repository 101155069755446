const subTitleStyles = (theme) => ({
  root: {
    color: theme.palette.ink.ink0,
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('lg')]: { marginBottom: theme.spacing(5) },
    [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(4) },
  },
});

export default subTitleStyles;
