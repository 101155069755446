import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const OptOut = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Opt-Out</Title>
    <Description>
      In connection with promotions or other projects, we may ask you
      specifically whether you have objections against a certain kind of data
      use or sharing. If you opt-out under such circumstances, we will respect
      your decision. To opt out of receiving commercial communications from
      Tapcentive, please click on the “opt-out” link in the communication or
      contact us at support@tapcentive.com. Please note that our affiliates and
      other data recipients have their own data privacy policies, which may
      differ from ours and should contact them separately with respect to
      opt-out requests.
    </Description>
  </Element>
);

export default OptOut;
