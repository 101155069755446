const smartScratchStyles = (theme) => ({
  root: { backgroundColor: '#1E1D30', overflow: 'hidden' },
  container: {
    position: 'relative',
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(33),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(10),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  videoContainer: {
    position: 'relative',
  },
});

export default smartScratchStyles;
