import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '../../../../components/Typography';
import subTitleStyles from './styles/subTitleStyles';

const SubTitle = ({ classes, children }) => (
  <Typography variant="h5" className={classes.root}>
    {children}
  </Typography>
);

export default withStyles(subTitleStyles)(SubTitle);
