const featuresStyles = (theme) => ({
  root: {
    [theme.breakpoints.between('md', 'lg')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  feature: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
});

export default featuresStyles;
