import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '../Typography';
import featureStyles from './styles/featureStyles';

const Feature = ({ classes, children, icon: Icon }) => (
  <Grid container spacing={2} alignItems="center" className={classes.root}>
    <Grid item xs="auto" md={12}>
      <Icon className={classes.icon} />
    </Grid>
    <Grid item>
      <Typography variant="body1">{children}</Typography>
    </Grid>
  </Grid>
);

export default withStyles(featureStyles)(Feature);
