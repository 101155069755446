import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '../../../../../../components/Typography';
import titleStyles from './styles/titleStyles';

const Title = ({ classes }) => (
  <Typography className={classes.root} component="h3">
    We helped introduce <span className={classes.bold}>smart cards</span> and{' '}
    <span className={classes.bold}>mobile technology</span> to power payments
    worldwide.
  </Typography>
);

export default withStyles(titleStyles)(Title);
