const featureStyles = (theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'nowrap',
    },
  },
  icon: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    [theme.breakpoints.down('lg')]: {
      width: theme.spacing(7.5),
      height: theme.spacing(7.5),
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(5.5),
      height: theme.spacing(5.5),
    },
  },
});

export default featureStyles;
