import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import capitalize from '../../utils/capitalize';
import withStyles from '@material-ui/core/styles/withStyles';
import typographyStyles from './styles/typographyStyles';

class Typography extends React.PureComponent {
  static propTypes = {
    align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    color: PropTypes.oneOf(['initial', 'inherit', 'primary', 'secondary']),
    weight: PropTypes.oneOf(['light']),
    component: PropTypes.string,
    display: PropTypes.oneOf(['initial', 'block', 'inline']),
    gutterBottom: PropTypes.bool,
    paragraph: PropTypes.bool,
    variant: PropTypes.oneOf([
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'body1',
      'body2',
      'body3',
      'button',
      'label',
      'navigation',
    ]),
  };

  static defaultProps = {
    align: 'inherit',
    color: 'initial',
    display: 'initial',
    gutterBottom: false,
    paragraph: false,
    variant: 'body1',
  };

  constructor(props) {
    super(props);
    this.variantMapping = {
      h1: 'h1',
      h2: 'h2',
      h3: 'h3',
      h4: 'h4',
      h5: 'h5',
      h6: 'h6',
      body1: 'p',
      body2: 'p',
      body3: 'p',
      label: 'h5',
    };
  }

  render() {
    const {
      classes,
      align,
      children,
      className,
      color,
      weight,
      component,
      display,
      gutterBottom,
      paragraph,
      variant,
      ...props
    } = this.props;

    const Component =
      component || (paragraph ? 'p' : this.variantMapping[variant]) || 'span';

    return (
      <Component
        className={classNames(
          classes.root,
          {
            [classes[variant]]: variant,
            [classes.gutterBottom]: gutterBottom,
            [classes[`align${capitalize(align)}`]]: align !== 'inherit',
            [classes[`color${capitalize(color)}`]]: color !== 'initial',
            [classes[`display${capitalize(display)}`]]: display !== 'initial',
            [classes[`weight${capitalize(weight)}`]]: weight,
          },
          className,
        )}
        {...props}
      >
        {children}
      </Component>
    );
  }
}

export default withStyles(typographyStyles)(Typography);
