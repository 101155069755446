import Image0 from '../images/img_0.jpg';
import Image1 from '../images/img_1.jpg';
import Image2 from '../images/img_2.png';
import Image3 from '../images/img_3.png';

const animationData = {
  v: '5.7.8',
  fr: 60,
  ip: 0,
  op: 361,
  w: 1920,
  h: 1080,
  nm: 'Tablet',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 768, h: 1024, u: '', p: Image0, e: 0 },
    { id: 'image_1', w: 768, h: 1024, u: '', p: Image1, e: 0 },
    { id: 'image_2', w: 480, h: 700, u: '', p: Image2, e: 0 },
    { id: 'image_3', w: 234, h: 148, u: '', p: Image3, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'Home - 4 games.png',
      cl: 'png',
      parent: 6,
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 105,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 120,
              s: [100],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 255,
              s: [100],
            },
            { t: 270, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [240, 336, 0], ix: 2, l: 2 },
        a: { a: 0, k: [384, 512, 0], ix: 1, l: 2 },
        s: { a: 0, k: [53, 53, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 3600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'Splash.png',
      cl: 'png',
      parent: 6,
      refId: 'image_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [240, 336, 0], ix: 2, l: 2 },
        a: { a: 0, k: [384, 512, 0], ix: 1, l: 2 },
        s: { a: 0, k: [53, 53, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 3600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: 'Tablet.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2, l: 2 },
        a: { a: 0, k: [240, 350, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 3600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: 'Card.png',
      cl: 'png',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.496], y: [0] },
                t: 46,
                s: [1500],
              },
              {
                i: { x: [0.75], y: [1] },
                o: { x: [0.496], y: [0] },
                t: 60,
                s: [1505],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 120,
                s: [1072],
              },
              {
                i: { x: [0.5], y: [1] },
                o: { x: [0.25], y: [0] },
                t: 240,
                s: [1072],
              },
              {
                i: { x: [0.5], y: [1] },
                o: { x: [0.167], y: [0] },
                t: 300,
                s: [1510],
              },
              { t: 330, s: [1500] },
            ],
            ix: 3,
          },
          y: { a: 0, k: 650, ix: 4 },
        },
        a: { a: 0, k: [117, 74, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 3600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 1,
      nm: 'White Solid 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2, l: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      sw: 1920,
      sh: 1080,
      sc: '#fbfbfb',
      ip: 0,
      op: 3600,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

export default animationData;
