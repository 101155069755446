import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as GameMathIcon } from '../../../../assets/images/game_math.svg';
import { ReactComponent as PricingIcon } from '../../../../assets/images/pricing.svg';
import { ReactComponent as GameOutcomesIcon } from '../../../../assets/images/game_outcomes.svg';
import { ReactComponent as GameUISIcon } from '../../../../assets/images/game_uis.svg';
import { ReactComponent as CardsIcon } from '../../../../assets/images/cards.svg';
import { ReactComponent as ReportingIcon } from '../../../../assets/images/reporting.svg';
import { ReactComponent as PlayerDataIcon } from '../../../../assets/images/player_data.svg';
import Feature from './components/Feature';
import featuresStyles from './styles/featuresStyles';

const Features = ({ classes }) => (
  <Grid container spacing={3}>
    <Grid item md={6} className={classes.feature}>
      <Feature icon={GameMathIcon} title="Game Math">
        Create your own game definition with game outcome cost, prize tiers and
        odds, and outcome pool size.
      </Feature>
    </Grid>
    <Grid item md={6} className={classes.feature}>
      <Feature icon={PricingIcon} title="Pricing">
        Define minimum purchase amount, subsequent/reload purchase amount, and
        daily and card lifetime purchase limits for responsible gaming.
      </Feature>
    </Grid>
    <Grid item md={6} className={classes.feature}>
      <Feature icon={GameOutcomesIcon} title="Game Outcomes">
        Securely generated by the platform and auditable with pool refresh rules
        to meet any regulatory requirements.
      </Feature>
    </Grid>
    <Grid item md={6} className={classes.feature}>
      <Feature icon={GameUISIcon} title="Game UIs">
        Players choose from a rotating selection of game experiences. Use our
        games or let us integrate games from your portfolio or your partners.
      </Feature>
    </Grid>
    <Grid item md={6} className={classes.feature}>
      <Feature icon={CardsIcon} title="Cards">
        Custom printed and packaged to meet operator requirements and ease
        integration with existing retailer and operator systems.
      </Feature>
    </Grid>
    <Grid item md={6} className={classes.feature}>
      <Feature icon={ReportingIcon} title="Financial Reporting">
        Comprehensive reporting capabilities to track all purchases,
        redemptions, and support for settlements between retailers and
        operators.
      </Feature>
    </Grid>
    <Grid item md={6} className={classes.feature}>
      <Feature icon={PlayerDataIcon} title="Player Data">
        Tracking of all mobile play provides operators powerful new insights
        into the behaviors and preferences of anonymous players.{' '}
      </Feature>
    </Grid>
  </Grid>
);

export default withStyles(featuresStyles)(Features);
