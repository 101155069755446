import isIE from '../../../../../../../utils/isIE';

const navigationItemStyles = (theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    margin: theme.spacing(3, 0),
    padding: theme.spacing(0, 3),
    cursor: 'pointer',
    '&:last-child': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
      margin: 0,
      padding: theme.spacing(2, 0),
    },
  },
  rootActive: {
    '&:after': {
      content: "''",
      position: 'absolute',
      top: '0',
      left: '0',
      width: '4px',
      height: '100%',
      backgroundColor: theme.palette.red.red60,
      [theme.breakpoints.down('md')]: {
        top: 'auto',
        bottom: '0',
        width: '100%',
        height: '2px',
      },
    },
  },
  text: { color: theme.palette.ink.ink60 },
  textActive: {
    textShadow: isIE()
      ? 'none'
      : `0 0 .65px ${theme.palette.red.red60}, 0 0 .65px ${theme.palette.red.red60}`,
    color: theme.palette.red.red60,
  },
});

export default navigationItemStyles;
