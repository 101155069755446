import isIE from '../../../../../../../utils/isIE';

const titleStyles = (theme) => ({
  root: {
    ...(isIE()
      ? {
          color: '#F24055',
        }
      : {
          background: 'linear-gradient(90deg, #F24055 0%, #FF8471 84.02%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'rgba(255, 255, 255, 0)',
        }),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: { marginBottom: '0' },
    [theme.breakpoints.down('xs')]: { marginBottom: theme.spacing(4) },
  },
});

export default titleStyles;
