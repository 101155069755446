import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Video from './components/Video';
import Container from '../../../../components/Container';
import Title from './components/Title';
import SubTitle from './components/SubTitle';
import Button from './components/Button';
import IPhone from './components/IPhone';
import Overlay from '../../../../components/Overlay';
import VideoPlayer from '../../../../components/VideoPlayer';
import SmartScratchVideo from './assets/videos/smartscratch_video.mp4';
import SmartScratchVideoPreview from './assets/images/smartscratch_video_preview.png';
import smartScratchStyles from './styles/smartScratchStyles';

const SmartScratch = ({ classes }) => {
  const [videoOverlay, setVideoOverlay] = useState(false);

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Hidden lgUp>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Title />
            </Grid>
            <Grid item xs={12}>
              <Video onClick={() => setVideoOverlay(true)} />
            </Grid>
            <Grid item xs={12} md={7}>
              <SubTitle />
              <Button>LEARN MORE</Button>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} md={5} className={classes.videoContainer}>
                <IPhone />
              </Grid>
            </Hidden>
          </Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={4}>
              <Title />
              <SubTitle />
              <Button>VIEW PRODUCT DETAILS</Button>
            </Grid>
            <Grid item xs={8} className={classes.videoContainer}>
              <Video onClick={() => setVideoOverlay(true)} />
              <IPhone />
            </Grid>
          </Grid>
        </Hidden>
      </Container>
      <Overlay open={videoOverlay} onClose={() => setVideoOverlay(false)}>
        <VideoPlayer
          controls
          sources={[
            {
              src: SmartScratchVideo,
              type: 'video/mp4',
            },
          ]}
          poster={SmartScratchVideoPreview}
          fluid
        />
      </Overlay>
    </div>
  );
};

export default withStyles(smartScratchStyles)(SmartScratch);
