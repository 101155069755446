const headerStyles = (theme) => ({
  root: {
    position: 'sticky',
    top: '0',
    zIndex: theme.variables.zIndex.header,
    width: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.ink.ink0,
  },
  container: {
    maxWidth: '1160px',
    margin: '0 auto',
    padding: theme.spacing(1.5, 4),
    '@supports (padding: max(0px))': {
      paddingLeft: `max(${theme.spacing(4)}px, env(safe-area-inset-left))`,
      paddingRight: `max(${theme.spacing(4)}px, env(safe-area-inset-right))`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      '@supports (padding: max(0px))': {
        paddingLeft: `max(${theme.spacing(2)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(2)}px, env(safe-area-inset-right))`,
      },
    },
  },
});

export default headerStyles;
