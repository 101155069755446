import React from 'react';
import { Element } from 'react-scroll';
import Cycle from '../../assets/images/cycle.jpg';
import Description from '../../../components/Description';
import Title from '../../../components/Title';
import Image from '../../../components/Image';

const PlayerJourney = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Easy to Purchase, Play, Redeem, and Reload.</Title>
    <Image src={Cycle} alt="cycle" />
    <Description>
      Players purchase SmartScratch cards from any authorized retailer. The
      purchase includes a bundle of plays based on the purchase price and the
      underlying game. Then, with just the tap of the card to their phone,
      players are drawn into the exciting world of mobile gaming via their
      mobile browser where they choose from multiple game reveal experiences and
      instantly discover what they have won.
    </Description>
    <Description>
      Players collect cash for winnings by returning to a retailer and
      presenting their card or they can use their phone to exchange their
      winnings for more plays. When they are out of plays and out of winnings,
      players can return to any retailer to purchase more plays on their card.
    </Description>
  </Element>
);

export default PlayerJourney;
