import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';
import Image from '../../../components/Image';
import Sales from '../../assets/images/sales.jpg';

const Retail = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Expand Sales In-Lane to Any Cash Register.</Title>
    <Image src={Sales} alt="scheme" />
    <Description>
      SmartScratch cards are manufactured to comply with the standards of the
      plastic gift card industry so that SmartScratch cards can be distributed
      and sold anywhere that a plastic gift card is sold. This means that an
      operator can offer SmartScratch for sale through retailers who sell gift
      cards but do not have a dedicated gaming terminal.
    </Description>
    <Description>
      For retailers, the process of selling SmartScratch is identical to selling
      a gift card. Players bring the card to the point-of-sale and pay for the
      desired play amount. The retailer activates the card using the same
      process that all other gift cards are activated. The simplicity of this
      solution is due to SmartScratch integration with existing gift card
      network operators. This opens the way to a significant expansion of sales
      with SmartScratch sold at every cash register and all at no capital cost
      to the operator.
    </Description>
  </Element>
);

export default Retail;
