const contactStyles = (theme) => ({
  root: { backgroundColor: theme.palette.red.red60 },
  container: {
    textAlign: 'center',
    maxWidth: '1160px',
    margin: '0 auto',
    padding: theme.spacing(15, 4),
    '@supports (padding: max(0px))': {
      paddingLeft: `max(${theme.spacing(4)}px, env(safe-area-inset-left))`,
      paddingRight: `max(${theme.spacing(4)}px, env(safe-area-inset-right))`,
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(8, 4),
      '@supports (padding: max(0px))': {
        paddingLeft: `max(${theme.spacing(4)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(4)}px, env(safe-area-inset-right))`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
      '@supports (padding: max(0px))': {
        paddingLeft: `max(${theme.spacing(2)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(2)}px, env(safe-area-inset-right))`,
      },
    },
  },
  textContainer: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('lg')]: { marginBottom: theme.spacing(3) },
    [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(4) },
  },
  title: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('lg')]: { marginBottom: theme.spacing(3) },
    [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(1) },
  },
  button: { [theme.breakpoints.down('xs')]: { width: '100%' } },
});

export default contactStyles;
