import React from 'react';
import Typography from '../../../../../../components/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import titleStyles from './styles/titleStyles';

const Title = ({ classes }) => (
  <Typography className={classes.root}>
    The fastest path to new <span className={classes.bold}>Revenue</span>
  </Typography>
);

export default withStyles(titleStyles)(Title);
