import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Collection = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Types of Data and Collection Methods</Title>
    <Description>
      Through the Websites, we receive data that you actively submit as well as
      data that we track:
    </Description>
    <Description>
      Actively Submitted Data: When you request information, subscribe to a
      mailing list or for a service, respond to an online survey or otherwise
      actively send us data, we may request data such as your name, email
      address, mailing address, telephone number, and certain information
      related to the business you represent such as number of employees and
      industry type. You can opt out of providing information by not entering it
      when asked and, if such information is required in order to allow us to
      respond to your inquiry, you will receive a notice advising you of this.
      In each such instance, you will know what data we collect through the
      Websites, because you actively submit it.
    </Description>
    <Description>
      Passively Tracked Data: When you visit the Websites, we also passively
      track information on your computing/mobile device and Internet connection,
      such as the IP address of your computing/mobile device and/or Internet
      service provider, the date and time you access the Websites, the Internet
      address of Websites from which you link to our Websites, the
      computing/mobile device technology you are using and your movements and
      preferences on our Websites. We use technology commonly employed on the
      web including, without limitation, browser “cookies” or small data files
      stored on your device, to collect such information. In some instances, we
      also use cookies to collect information in connection with future visits
      from a website, to recognize you as a previous visitor, or to track your
      activity on our Websites. If you follow a link to the site from an
      affiliate or promotion, we store information about your arrival from that
      affiliate or promotion in a session cookie. You do not need to have
      cookies turned on to visit our Websites. You can refuse cookies by turning
      them off on your browser.
    </Description>
    <Description>
      Combinations: Unless you also actively submit personal information such as
      your name or address, passively tracked data does not typically allow us
      to identify you personally, and we do not endeavor to do so unless you
      identify yourself voluntarily through an active submission of data. To the
      extent permitted by applicable law, we reserve the right to combine
      passively tracked data with personal data that you actively submit.
    </Description>
  </Element>
);

export default Collection;
