const photoStyles = (theme) => ({
  root: {
    position: 'relative',
    display: 'inline-block',
    padding: theme.spacing(0, 0, 6, 5),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 0, 6, 5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 60px 42px 60px',
    },
  },
  image: {
    display: 'block',
    width: '240px',
    boxShadow: '0px 27.0786px 27.0786px rgba(34, 41, 41, 0.15)',
    borderRadius: '12.7429px',
    [theme.breakpoints.down('lg')]: {
      width: '160px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '140px',
    },
  },
  description: {
    position: 'absolute',
    left: '0',
    bottom: '0',
    padding: theme.spacing(0.5, 2.5),
    borderRadius: '10px',
    backgroundColor: theme.palette.ink.ink90,
    minWidth: '180px',
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      minWidth: 'unset',
    },
  },
  title: {
    color: theme.palette.ink.ink5,
    marginBottom: theme.spacing(0.5),
  },
  subtitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  subtitle: {
    color: theme.palette.ink.ink40,
  },
  dot: {
    display: 'block',
    width: theme.spacing(0.5),
    height: theme.spacing(0.5),
    borderRadius: '50%',
    backgroundColor: theme.palette.ink.ink40,
  },
  linkedin: {
    color: theme.palette.red.red60,
  },
});

export default photoStyles;
