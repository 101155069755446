const titleStyles = (theme) => ({
  root: {
    maxWidth: '862px',
    fontWeight: 300,
    fontSize: '56px',
    lineHeight: '150%',
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('lg')]: {
      fontSize: '38px',
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: '130%',
      marginBottom: theme.spacing(4),
    },
  },
  bold: { fontWeight: 'bold' },
});

export default titleStyles;
