const overlayStyles = (theme) => ({
  root: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: theme.variables.zIndex.video,
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    msOverflowStyle: '-ms-autohiding-scrollbar',
  },
  content: {
    position: 'absolute',
    bottom: '50%',
    right: '50%',
    transform: 'translate(50%, 50%)',
    width: '90%',
    height: '80vh',
    maxHeight: '60vw',
    padding: theme.spacing(2.5),
    maxWidth: theme.breakpoints.values.xl,
    [theme.breakpoints.down('lg')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.breakpoints.values.sm,
      padding: theme.spacing(2),
    },
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '50%',
    backgroundColor: theme.palette.ink.ink0,
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': { backgroundColor: theme.palette.ink.ink5 },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) rotate(45deg)',
      width: '60%',
      height: '2px',
      backgroundColor: theme.palette.red.red60,
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) rotate(-45deg)',
      width: '60%',
      height: '2px',
      backgroundColor: theme.palette.red.red60,
    },
  },
});

export default overlayStyles;
