import React from 'react';
import Typography from '../../../../../../components/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import subTitleStyles from './styles/subTitleStyles';

const SubTitle = ({ classes }) => (
  <Typography variant="body2" className={classes.root}>
    SmartScratch is only available from Tapcentive. It is the easiest way to
    welcome all players to the exciting world of mobile gaming.
  </Typography>
);

export default withStyles(subTitleStyles)(SubTitle);
