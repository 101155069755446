import React from 'react';
import Typography from '../../../../../../components/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import titleStyles from './styles/titleStyles';

const Title = ({ classes }) => (
  <Typography variant="h3" className={classes.root}>
    Transforming Lottery, Casino, and Charitable Gaming Through the Power of
    Smart Card Technology
  </Typography>
);

export default withStyles(titleStyles)(Title);
