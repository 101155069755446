import React from 'react';
import { Link } from 'react-scroll';
import classNames from 'classnames';
import Typography from '../../../../../../components/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import navigationItemStyles from './styles/navigationItemStyles';

const NavigationItem = ({ classes, children, id, active }) => (
  <Link
    className={classNames(classes.root, {
      [classes.rootActive]: active,
    })}
    to={id}
    smooth
    spy
    offset={-112}
  >
    <Typography
      variant="navigation"
      className={classNames(classes.text, {
        [classes.textActive]: active,
      })}
    >
      {children}
    </Typography>
  </Link>
);

export default withStyles(navigationItemStyles)(NavigationItem);
