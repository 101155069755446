import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

class VideoPlayer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    this.player = videojs(this.videoRef.current, {
      autoplay: true,
      fill: true,
      controlBar: {
        pictureInPictureToggle: false,
      },
      ...this.props,
    });
    this.player.fill(true);
  }

  render() {
    return (
      <video
        ref={this.videoRef}
        className="video-js vjs-fill vjs-big-play-centered"
      />
    );
  }
}

export default VideoPlayer;
