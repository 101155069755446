import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Rights = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Intellectual Property Rights</Title>
    <Description>
      The Websites and their entire contents, features and functionality
      (including but not limited to all information, software, text, displays,
      images, video and audio, and the design, selection and arrangement
      thereof), are owned by Tapcentive, its licensors or other providers of
      such material and are protected by United States and international
      copyright, trademark, patent, trade secret and other intellectual property
      or proprietary rights laws.
    </Description>
    <Description>
      These Terms permit you to use these Websites for your personal,
      non-commercial use only. You must not reproduce, distribute, modify,
      create derivative works of, publicly display, publicly perform, republish,
      download, store or transmit any of the material on our Website, except to
      reasonably use these Websites.
    </Description>
  </Element>
);

export default Rights;
