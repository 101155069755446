import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import IPhoneXR from '../../assets/images/iphone_xr.png';
import iPhoneStyles from './styles/iPhoneStyles';

const IPhone = ({ classes }) => (
  <img src={IPhoneXR} alt="" className={classes.root} />
);

export default withStyles(iPhoneStyles)(IPhone);
