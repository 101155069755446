import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '../../../../components/Container';
import SupTitle from './components/SupTitle';
import Title from './components/Title';
import SubTitle from './components/SubTitle';
import Links from './components/Links';
import Photos from './components/Photos';
import ContactPopup from '../../../../components/ContactPopup';
import contactStyles from './styles/contactStyles';

const Contact = ({ classes }) => {
  const [contactFormVisible, setContactFormVisible] = useState(false);

  return (
    <>
      <div className={classes.root}>
        <Container>
          <Hidden mdUp>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SupTitle />
              </Grid>
              <Grid item xs={12}>
                <Title />
              </Grid>
              <Grid item xs={12} className={classes.photosContainer}>
                <Photos />
              </Grid>
              <Grid item xs={12}>
                <SubTitle />
              </Grid>
              <Grid item xs={12}>
                <Links onClick={() => setContactFormVisible(true)} />
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid container spacing={4} justify="space-between">
              <Grid item md={8} lg={9} xl={6}>
                <Grid container spacing={0}>
                  <Grid item md={9} xl={12}>
                    <SupTitle />
                    <Title />
                  </Grid>
                  <Grid item md={12}>
                    <SubTitle />
                    <Links onClick={() => setContactFormVisible(true)} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md="auto" className={classes.photosContainer}>
                <Photos />
              </Grid>
            </Grid>
          </Hidden>
        </Container>
      </div>
      {contactFormVisible && (
        <ContactPopup onClose={() => setContactFormVisible(false)} />
      )}
    </>
  );
};

export default withStyles(contactStyles)(Contact);
