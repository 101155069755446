import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '../../../../../../components/Typography';
import subTitleStyles from './styles/subTitleStyles';

const SubTitle = ({ classes }) => (
  <Grid container spacing={4} className={classes.root}>
    <Grid item lg={6} className={classes.item}>
      <Typography variant="body1">
        Gaming operators with physical venues struggle with the decision of how
        many gaming machines to purchase and where to deploy them to maximize
        gaming revenue. Gaming vendors with a growing catalog of new games and
        licensing partnerships struggle with the challenge of having more to
        offer within the same physical space.
      </Typography>
    </Grid>
    <Grid item lg={6} className={classes.item}>
      <Typography variant="body1">
        That all changes with Smart Chip Gaming. All gaming takes place within
        the card and tablet; no remote servers or network connections required.
        If you have space for tablets in your operation, then you have space for
        Smart Chip Gaming. It is the fastest way to increase revenue by putting
        low-cost machines in the hands of players to let them play securely and
        reliably when and where they choose.
      </Typography>
    </Grid>
  </Grid>
);

export default withStyles(subTitleStyles)(SubTitle);
