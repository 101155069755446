import React from 'react';
import SupTitle from './components/SupTitle';
import Title from './components/Title';
import SubTitle from './components/SubTitle';
import Features from './components/Features';
import ButtonBlock from './components/ButtonBlock';
import Container from '../../../../components/Container';
import Video from './components/Video';

const SmartChipGaming = () => (
  <Container>
    <SupTitle />
    <Title />
    <Features />
    <Video />
    <SubTitle />
    <ButtonBlock />
  </Container>
);

export default SmartChipGaming;
