const contentStyles = (theme) => ({
  root: {
    position: 'relative',
    maxWidth: '1160px',
    margin: '0 auto',
    padding: theme.spacing(10, 4),
    '@supports (padding: max(0px))': {
      paddingLeft: `max(${theme.spacing(4)}px, env(safe-area-inset-left))`,
      paddingRight: `max(${theme.spacing(4)}px, env(safe-area-inset-right))`,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 4),
      '@supports (padding: max(0px))': {
        paddingLeft: `max(${theme.spacing(4)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(4)}px, env(safe-area-inset-right))`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 2),
      '@supports (padding: max(0px))': {
        paddingLeft: `max(${theme.spacing(2)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(2)}px, env(safe-area-inset-right))`,
      },
    },
  },
  navigation: {},
  content: {
    [theme.breakpoints.down('md')]: { padding: theme.spacing(4) },
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(5, 2) },
  },
});

export default contentStyles;
