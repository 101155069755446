import React from 'react';
import { Element } from 'react-scroll';
import withStyles from '@material-ui/core/styles/withStyles';
import Description from '../../../components/Description';
import Title from '../../../components/Title';
import Animation from './components/Animation';
import approachStyles from './styles/approachStyles';

const Approach = ({ classes, id }) => (
  <Element name={id} id={id}>
    <Title>A New Approach to Secure, Reliable, Portable Gaming</Title>
    <Animation />
    <Description>
      Smart Chip Gaming is a unique, patented approach to GLI-11 gaming devices
      that combines the power and security of a smart card with an off-the-shelf
      mobile tablet and any HTML5 game content. The result is a secure,
      reliable, low-cost portable gaming solution that offers gaming operators
      and vendors a whole new way of expanding gaming operations. With Smart
      Chip Gaming, now you can truly allow players to game anywhere and anytime.
    </Description>
    <Description>
      <span className={classes.bold}>Game Card:</span> A bank-grade, secure,
      smart chip game card that stores all gaming logic, RNGs and gaming funds
      for use with a tablet. All gaming operations, including the determination
      of gaming outcomes, execute within the game card’s tamper-resistant
      microprocessor core.
    </Description>
    <Description>
      <span className={classes.bold}>Tablet & Case:</span> The tablet is an
      off-the-shelf tablet computer wrapped in a protective case that includes
      an integrated card reader. The tablet software stores all gaming graphics
      and provides the player interface for the games. With a game card inserted
      in the case’s reader slot, the game graphics in the tablet communicate
      with the game card to initiate play and retrieve results.
    </Description>
    <Description>
      <span className={classes.bold}>Games:</span> Games can be any HTML5 game
      content. To add a game to the Smart Chip Gaming system, Tapcentive imports
      the HTML5 content into the tablet software and implements the game’s logic
      and RNG in the game card’s secure firmware.
    </Description>
    <Description>
      <span className={classes.bold}>Cage Device:</span> The system includes a
      secure card-reading device, similar to a mobile point-of-sale device with
      receipt printer. The device is used to add and remove a player’s gaming
      credits from game cards and to perform other reporting and loyalty
      functions.
    </Description>
  </Element>
);

export default withStyles(approachStyles)(Approach);
