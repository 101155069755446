import React from 'react';
import Lottie from 'react-lottie';
import animationData from './assets/js/animationData';

const defaultOptions = {
  renderer: 'svg',
  loop: true,
  autoplay: true,
  animationData: animationData,
};

const Animation = () => <Lottie options={defaultOptions} />;

export default Animation;
