import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Reliance = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Reliance on Information Posted</Title>
    <Description>
      The information presented on or through these Websites is made available
      solely for general information purposes. We do not warrant the accuracy,
      completeness or usefulness of this information. Any reliance you place on
      such information is strictly at your own risk. We disclaim all liability
      and responsibility arising from any reliance placed on such materials by
      you or any other visitor to these Websites, or by anyone who may be
      informed of any of its contents.
    </Description>
    <Description>
      These Websites may include content provided by third parties, including
      materials provided by other users, bloggers and third-party licensors,
      syndicators, aggregators and/or reporting services. All statements and/or
      opinions expressed in these materials, and all articles and responses to
      questions and other content, other than the content provided by the
      Company, are solely the opinions and the responsibility of the person or
      entity providing those materials. These materials do not necessarily
      reflect the opinion of the Company. We are not responsible, or liable to
      you or any third party, for the content or accuracy of any materials
      provided by any third parties.
    </Description>
  </Element>
);

export default Reliance;
