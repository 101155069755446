const contactPopupStyles = (theme) => ({
  root: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: theme.variables.zIndex.contactPopup,
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    msOverflowStyle: '-ms-autohiding-scrollbar',
  },
  container: {
    position: 'absolute',
    bottom: '50%',
    right: '50%',
    transform: 'translate(50%, 50%)',
    width: '80%',
    maxWidth: '564px',
    padding: theme.spacing(8),
    backgroundColor: theme.palette.ink.ink0,
    borderRadius: '6px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: theme.spacing(5, 2, 2),
    },
  },
  title: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(2) },
  },
  subTitle: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(2) },
  },
  fieldGroup: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(2) },
  },
  buttonContainer: { textAlign: 'center' },
  button: { [theme.breakpoints.down('sm')]: { width: '100%' } },
  error: {
    color: theme.palette.red.red70,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(2) },
  },
  close: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    borderRadius: '50%',
    backgroundColor: theme.palette.ink.ink0,
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': { backgroundColor: theme.palette.ink.ink5 },
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(1),
      right: theme.spacing(1),
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) rotate(45deg)',
      width: '60%',
      height: '2px',
      backgroundColor: theme.palette.red.red60,
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) rotate(-45deg)',
      width: '60%',
      height: '2px',
      backgroundColor: theme.palette.red.red60,
    },
  },
  icon: {
    display: 'block',
    margin: '0 auto',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      marginBottom: theme.spacing(2),
    },
  },
});

export default contactPopupStyles;
