import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Logo from './components/Logo';
import Navigation from './components/Navigation';
import headerStyles from './styles/headerStyles';

const Header = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.container}>
      <Grid container spacing={4} alignItems="center" justify="space-between">
        <Grid item xs="auto">
          <Logo />
        </Grid>
        <Grid item xs="auto" md={10} lg={8}>
          <Navigation />
        </Grid>
      </Grid>
    </div>
  </div>
);

export default withStyles(headerStyles)(Header);
