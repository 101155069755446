const supTitleStyles = (theme) => ({
  root: {
    color: theme.palette.ink.ink50,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('lg')]: { marginBottom: theme.spacing(2) },
    [theme.breakpoints.down('sm')]: { marginBottom: 0 },
  },
});

export default supTitleStyles;
