const bannerStyles = (theme) => ({
  root: {
    backgroundColor: '#1F2023',
    paddingTop: theme.spacing(9),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
    },
  },
});

export default bannerStyles;
