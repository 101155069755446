import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-scroll';
import Typography from '../../../../Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import navigationItemStyles from './styles/navigationItemStyles';

const NavigationItem = ({ classes, children, id, active, onClick }) => (
  <Link
    className={classNames(classes.root, {
      [classes.active]: active,
    })}
    to={id}
    smooth
    spy
    offset={-56}
    onClick={onClick}
  >
    <Typography
      variant="navigation"
      className={classNames(classes.text, {
        [classes.textActive]: active,
      })}
    >
      {children}
    </Typography>
  </Link>
);

export default withStyles(navigationItemStyles)(NavigationItem);
