import React from 'react';
import Lottie from 'react-lottie';
import animationData from './assets/js';

const defaultOptions = {
  renderer: 'svg',
  loop: true,
  autoplay: true,
  animationData: animationData,
};

const Animation = () => (
  <Lottie options={defaultOptions} isClickToPauseDisabled />
);

export default Animation;
