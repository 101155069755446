const photosStyles = (theme) => ({
  root: {
    position: 'relative',
    display: 'inline-block',
    padding: '0 190px 190px 0',
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      top: '0',
      right: '0',
      padding: theme.spacing(0, 14, 21, 0),
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      display: 'block',
      padding: '0',
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
  },
  photo: {
    display: 'block',
    '&:not(:last-child)': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
  },
  overlapPhoto: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      bottom: 'auto',
      right: 'auto',
    },
  },
});

export default photosStyles;
