import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const ChangesWebsites = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Changes to these Websites</Title>
    <Description>
      We may update the content on these Websites from time to time, but the
      content is not necessarily complete or up-to-date. Any of the material on
      these Websites may be out of date at any given time, and we are under no
      obligation to update such material.
    </Description>
  </Element>
);

export default ChangesWebsites;
