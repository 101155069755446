import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '../Typography';
import capitalize from '../../utils/capitalize';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonStyles from './styles/buttonStyles';

const Button = ({
  classes,
  children,
  className,
  onClick,
  variant = 'primary',
  disabled,
  component = 'div',
  ...props
}) =>
  component === 'button' ? (
    <button
      className={classNames(
        classes.root,
        {
          [classes[`variant${capitalize(variant)}`]]: variant,
          [classes.disabled]: disabled,
        },
        className,
      )}
      onClick={disabled ? () => {} : onClick}
      {...props}
    >
      <Typography variant="button" className={classes.text}>
        {children}
      </Typography>
    </button>
  ) : (
    <div
      className={classNames(
        classes.root,
        {
          [classes[`variant${capitalize(variant)}`]]: variant,
          [classes.disabled]: disabled,
        },
        className,
      )}
      onClick={disabled ? () => {} : onClick}
      {...props}
    >
      <Typography variant="button" className={classes.text}>
        {children}
      </Typography>
    </div>
  );

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'black']),
  onClick: PropTypes.func,
};

export default withStyles(buttonStyles)(Button);
