import React from 'react';
import Typography from '../../../../../../components/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import titleStyles from './styles/titleStyles';

const Title = ({ classes }) => (
  <Typography className={classes.root} component="h2">
    Instant-win games sold in a{' '}
    <span className={classes.bold}>Reloadable Card</span> and experienced on a
    phone
  </Typography>
);

export default withStyles(titleStyles)(Title);
