import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '../../../../components/Typography';
import bannerStyles from './styles/bannerStyles';

const Banner = ({ classes, title, mobileTitle }) => (
  <div className={classes.root}>
    <div className={classes.container}>
      <Grid container spacing={4} alignItems="center" justify="center">
        <Grid item xs="auto">
          <Typography variant="h1" color="secondary" align="center">
            <Hidden xlUp>{mobileTitle}</Hidden>
            <Hidden lgDown>{title}</Hidden>
          </Typography>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default withStyles(bannerStyles)(Banner);
