import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';
import Link from '../../../components/Link';

const Information = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Information Privacy</Title>
    <Description>
      All information we collect on these Websites is subject to our{' '}
      <Link href="/privacy-policy" router>
        Privacy Policy
      </Link>
      . By using these Websites, you consent to all actions taken by us with
      respect to your information in compliance with the Privacy Policy.
    </Description>
  </Element>
);

export default Information;
