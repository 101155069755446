import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '../../../components/Container';
import Title from '../components/Title';
import Footer from '../components/Footer';
import Description from '../components/Description';
import tabletPrivacyStyles from './styles/tabletPrivacyStyles';

const TabletPrivacy = ({ classes }) => (
  <Container className={classes.root}>
    <div className={classes.content}>
      <Title
        title="Tapcentive Smart Chip Gaming App Privacy Policy"
        subtitle="Last updated on August 14, 2020"
      />
      <Description
        title="Information We Collect or Receive"
        subtitle="We don't collect, store, or share your data, period."
      />
    </div>
    <Footer />
  </Container>
);

export default withStyles(tabletPrivacyStyles)(TabletPrivacy);
