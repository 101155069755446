import React from 'react';
import classNames from 'classnames';
import { ReactComponent as PlayIcon } from './assets/images/play.svg';
import withStyles from '@material-ui/core/styles/withStyles';
import videoPreviewStyles from './styles/videoPreviewStyles';

const VideoPreview = ({ classes, className, image, onClick }) => (
  <div className={classNames(classes.root, className)} onClick={onClick}>
    <img src={image} alt="" />
    <PlayIcon className={classes.play} />
  </div>
);

export default withStyles(videoPreviewStyles)(VideoPreview);
