const videoStyles = (theme) => ({
  videoPreview: {
    boxShadow: '0px 20px 30px rgba(96, 102, 122, 0.13)',
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('lg')]: { marginBottom: theme.spacing(5) },
    [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(4) },
  },
});

export default videoStyles;
