const linksStyles = (theme) => ({
  linkContainer: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      '&:not(:last-child):after': {
        content: '"|"',
        position: 'absolute',
        left: '100%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        fontFamily: '"Lato", "Helvetica", sans-serif',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '140%',
        color: theme.palette.ink.ink40,
      },
    },
  },
  link: {
    fontFamily: '"Lato", "Helvetica", sans-serif',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '140%',
    color: theme.palette.red.red60,
    textDecoration: 'none',
    cursor: 'pointer',
  },
});

export default linksStyles;
