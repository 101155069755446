import React from 'react';
import Photo from './components/Photo';
import DaveImage from '../../assets/images/dave.png';
import MikeImage from '../../assets/images/mike.png';
import withStyles from '@material-ui/core/styles/withStyles';
import photosStyles from './styles/photosStyles';

const Photos = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.photo}>
      <Photo
        src={DaveImage}
        title="Dave Wentker"
        subtitle="CEO"
        linkedinUrl="https://www.linkedin.com/in/davewentker/"
      />
    </div>
    <div className={classes.photo}>
      <Photo
        className={classes.overlapPhoto}
        src={MikeImage}
        title="Mike Lindelsee"
        subtitle="CTO"
        linkedinUrl="https://www.linkedin.com/in/mikelindelsee/"
      />
    </div>
  </div>
);

export default withStyles(photosStyles)(Photos);
