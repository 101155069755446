import React from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '../../../../../../components/Button';
import buttonBlockStyles from './styles/buttonBlockStyles';

const ButtonBlock = ({ classes }) => (
  <div className={classes.root}>
    <Link to="/smart-chip-gaming">
      <Button className={classes.button}>VIEW PRODUCT DETAILS</Button>
    </Link>
  </div>
);

export default withStyles(buttonBlockStyles)(ButtonBlock);
