const navigationStyles = (theme) => ({
  root: {
    position: 'fixed',
    top: theme.spacing(7),
    left: '0',
    width: '100%',
    backgroundColor: '#fff',
  },
  menuButton: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: '5px 2px',
    cursor: 'pointer',
  },
  menuButtonContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  menuButtonItem: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.ink.ink100,
    '&:nth-child(2)': { transform: 'translateY(-50%)' },
    '&:nth-child(3)': { transform: 'translateY(-100%)' },
  },
});

export default navigationStyles;
