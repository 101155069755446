import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';
import Image from '../../../components/Image';
import Devices from '../../assets/images/devices.jpg';

const Markets = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Big Market with Limited Infrastructure? No Problem.</Title>
    <Image src={Devices} alt="scheme" />
    <Description>
      If you are a gaming operator, mobile network operator, or vendor in a
      regulated gaming market and want to offer SmartScratch but you have
      limited or no dedicated infrastructure or terminals, SmartScratch is
      designed for you too. The SmartScratch platform includes the ability to
      transform any mobile phone into a secure terminal for sales and
      redemptions by simply downloading an app.
    </Description>
    <Description>
      With our app-based solution, SmartScratch can be securely deployed across
      agent networks of sellers to reach the most remote corners of a market and
      to do so with an economic and security model that works for everyone far
      better than paper games ever can.
    </Description>
  </Element>
);

export default Markets;
