import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '../../../components/Container';
import Title from '../components/Title';
import Footer from '../components/Footer';
import Description from '../components/Description';
import Typography from '../../../components/Typography';
import tabletPrivacyPAStyles from './styles/tabletPrivacyPAStyles';

const TabletPrivacyPA = ({ classes }) => (
  <Container className={classes.root}>
    <div className={classes.content}>
      <Title
        title="PA Lottery App Privacy Policy"
        subtitle="Last updated on May 24, 2023"
      />
      <Description
        title="Information We Collect or Receive"
        subtitle="The following data may be collected but is not linked to your identity:"
      />
      <Typography className={classes.listHeader}>Usage Data</Typography>
      <ul className={classes.list}>
        <li>
          <Typography>Product Interaction</Typography>
        </li>
        <li>
          <Typography>Other Usage Data</Typography>
        </li>
      </ul>
      <Typography className={classes.listHeader}>Diagnostics</Typography>
      <ul className={classes.list}>
        <li>
          <Typography>Crash Data</Typography>
        </li>
        <li>
          <Typography>Performance Data</Typography>
        </li>
        <li>
          <Typography>Other Diagnostic Data</Typography>
        </li>
      </ul>
      <Description
        title="Information Usage:"
        subtitle="The information we collect may be used for:"
      />
      <ul className={classes.list}>
        <li>
          <Typography>
            conducting lottery-related financial and usage reporting
          </Typography>
        </li>
        <li>
          <Typography>
            monitoring technical operations of the mobile app
          </Typography>
        </li>
        <li>
          <Typography>
            risk management, including ensuring of end-to-end integrity of the
            lottery
          </Typography>
        </li>
      </ul>
    </div>
    <Footer />
  </Container>
);

export default withStyles(tabletPrivacyPAStyles)(TabletPrivacyPA);
