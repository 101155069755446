import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Integration = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Seamlessly Integrate With Existing Terminals.</Title>
    <Description>
      Gaming operators have a lot invested in terminals and infrastructure that
      enable them to sell and redeem game tickets of all types. SmartScratch has
      been designed to fit right in, with SmartScratch cards activated for sale
      and redeemed for cash through those same terminals thanks to simple
      integration with the SmartScratch platform.
    </Description>
    <Description>
      Integration with existing terminals and infrastructure means no changes to
      the processes and accounting practices already in-place by an operator for
      tracking sales, redemptions, and paying commissions.
    </Description>
  </Element>
);

export default Integration;
