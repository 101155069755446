import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import overlayStyles from './styles/overlayStyles';

const Overlay = ({ classes, children, open, onClose }) =>
  open && (
    <div className={classes.root} onClick={onClose}>
      <div
        className={classes.content}
        onClick={(event) => event.stopPropagation()}
      >
        {children}
        <div className={classes.close} onClick={onClose} />
      </div>
    </div>
  );

export default withStyles(overlayStyles)(Overlay);
