import isIE from '../../../../../utils/isIE';

const navigationStyles = (theme) => ({
  root: {
    position: 'sticky',
    top: theme.spacing(13),
    backgroundColor: theme.palette.ink.ink0,
    boxShadow: '0 20px 50px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    zIndex: theme.variables.zIndex.navigation,
    padding: theme.spacing(4, 3),
    '@supports (padding: max(0px))': {
      paddingLeft: `max(${theme.spacing(3)}px, env(safe-area-inset-left))`,
      paddingRight: `max(${theme.spacing(3)}px, env(safe-area-inset-right))`,
    },
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(8),
      padding: theme.spacing(0, 4),
      '@supports (padding: max(0px))': {
        paddingLeft: `max(${theme.spacing(4)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(4)}px, env(safe-area-inset-right))`,
      },
      borderRadius: '0',
    },
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(7),
      padding: theme.spacing(0, 2),
      '@supports (padding: max(0px))': {
        paddingLeft: `max(${theme.spacing(2)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(2)}px, env(safe-area-inset-right))`,
      },
    },
  },
  container: {
    position: 'relative',
    overflowY: 'hidden',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      whiteSpace: 'nowrap',
      overflowX: isIE() ? 'hidden' : 'auto',
      WebkitOverflowScrolling: 'touch',
      msOverflowStyle: '-ms-autohiding-scrollbar',
      '&::-webkit-scrollbar': { display: 'none' },
    },
  },
  fadeLeft: {
    '&:before': {
      content: "''",
      position: 'absolute',
      display: 'block',
      top: '0',
      left: '0',
      width: '30%',
      height: '100%',
      background:
        'linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 59.58%)',
      pointerEvents: 'none',
      zIndex: theme.variables.zIndex.navigationFade,
    },
  },
  fadeRight: {
    '&:after': {
      content: "''",
      position: 'absolute',
      display: 'block',
      top: '0',
      right: '0',
      width: '30%',
      height: '100%',
      background:
        'linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 59.58%)',
      pointerEvents: 'none',
      zIndex: theme.variables.zIndex.navigationFade,
    },
  },
  title: {},
  separator: { margin: theme.spacing(3, 0) },
});

export default navigationStyles;
