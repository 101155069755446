import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '../../../../../../components/Typography';
import subTitleStyles from './styles/subTitleStyles';

const SubTitle = ({ classes }) => (
  <Grid container spacing={4}>
    <Grid item md={6} className={classes.root}>
      <Typography variant="body1" className={classes.text}>
        Players love the instant gratification from purchasing a paper scratch
        ticket or pull-tab and instantly discovering if they are a winner. But
        today's players also want the excitement of mobile gaming. To offer
        mobile gaming, operators have had to invest in full Internet gaming
        systems that require players to register, link their banking/card
        details, and make a deposit before they can enjoy their first play.
      </Typography>
    </Grid>
    <Grid item md={6} className={classes.root}>
      <Typography variant="body1" className={classes.text}>
        That all changes with SmartScratch. It’s the best of both the physical
        and mobile worlds. Players purchase a reloadable chip card in the retail
        environment. With a tap of the card to their phone, the mobile gaming
        begins—no registration required. To cash out their winnings, players
        return to retail and present their card. For operators it is even easier
        and more secure to deploy than today's paper games.
      </Typography>
    </Grid>
  </Grid>
);

export default withStyles(subTitleStyles)(SubTitle);
