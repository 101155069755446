const notFoundStyles = (theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.palette.ink.ink5,
  },
  container: {
    flexGrow: 1,
    flexShrink: 0,
    width: '100%',
    maxWidth: '1160px',
    margin: '0 auto',
    padding: theme.spacing(8, 4),
    '@supports (padding: max(0px))': {
      paddingLeft: `max(${theme.spacing(4)}px, env(safe-area-inset-left))`,
      paddingRight: `max(${theme.spacing(4)}px, env(safe-area-inset-right))`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 4),
      '@supports (padding: max(0px))': {
        paddingLeft: `max(${theme.spacing(4)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(4)}px, env(safe-area-inset-right))`,
      },
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 2),
      '@supports (padding: max(0px))': {
        paddingLeft: `max(${theme.spacing(2)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(2)}px, env(safe-area-inset-right))`,
      },
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '128px',
    lineHeight: '110%',
    marginBottom: theme.spacing(2),
    color: theme.palette.red.red60,
    [theme.breakpoints.down('lg')]: {
      fontSize: '104px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '80px',
    },
  },
  subTitle: {},
  description: {
    marginBottom: theme.spacing(2),
  },
  link: {
    color: theme.palette.red.red60,
  },
});

export default notFoundStyles;
