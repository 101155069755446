import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const Comments = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Comments and Concerns</Title>
    <Description>
      These websites are operated by Tapcentive, Inc.. We welcome your feedback.
      Feedback, comments, requests for technical support and other
      communications relating to these Websites should be directed to:
      support@tapcentive.com.
    </Description>
  </Element>
);

export default Comments;
