import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from 'react-router-dom';
import ContactPopup from '../ContactPopup';
import Typography from '../Typography';
import footerStyles from './styles/footerStyles';

const Footer = ({ classes }) => {
  const [contactFormVisible, setContactFormVisible] = useState(false);

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={12} md="auto" className={classes.linkContainer}>
            <Typography variant="body3">
              © {new Date().getFullYear()} Tapcentive Inc.
            </Typography>
          </Grid>
          <Grid item xs={12} md="auto" className={classes.linkContainer}>
            <Typography
              variant="body3"
              className={classes.link}
              onClick={() => setContactFormVisible(true)}
            >
              Contact Us
            </Typography>
          </Grid>
          <Grid item xs={12} md="auto" className={classes.linkContainer}>
            <RouterLink to="/terms-of-use" className={classes.link}>
              <Typography variant="body3">Terms of Use</Typography>
            </RouterLink>
          </Grid>
          <Grid item xs={12} md="auto" className={classes.linkContainer}>
            <RouterLink to="/privacy-policy" className={classes.link}>
              <Typography variant="body3">Privacy Policy</Typography>
            </RouterLink>
          </Grid>
        </Grid>
      </div>
      {contactFormVisible && (
        <ContactPopup onClose={() => setContactFormVisible(false)} />
      )}
    </>
  );
};

export default withStyles(footerStyles)(Footer);
