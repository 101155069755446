const titleStyles = (theme) => ({
  root: {
    color: theme.palette.ink.ink0,
    maxWidth: '862px',
    fontWeight: 300,
    fontSize: '40px',
    lineHeight: '140%',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('lg')]: {
      fontSize: '32px',
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: { fontSize: '22px', lineHeight: '130%' },
  },
  bold: { fontWeight: 'bold' },
});

export default titleStyles;
