const titleStyles = (theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontWeight: 'bold',
  },
});

export default titleStyles;
