const contactStyles = (theme) => ({
  root: { backgroundColor: theme.palette.ink.ink80 },
  title: {
    color: theme.palette.ink.ink0,
    maxWidth: '862px',
    fontWeight: 300,
    fontSize: '56px',
    lineHeight: '150%',
    [theme.breakpoints.down('sm')]: { fontSize: '38px' },
    [theme.breakpoints.down('xs')]: { fontSize: '32px', lineHeight: '130%' },
  },
  bold: { fontWeight: 'bold' },
  subTitle: { color: theme.palette.ink.ink0 },
  button: {},
  video: { width: '100%' },
  photosContainer: {
    position: 'relative',
  },
});

export default contactStyles;
