import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import separatorStyles from './styles/separatorStyles';

const Separator = ({ classes, className }) => (
  <div className={classNames(classes.root, className)} />
);

export default withStyles(separatorStyles)(Separator);
