import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoImage } from '../../../../../../assets/images/logo.svg';
import { ReactComponent as LogoMobileImage } from '../../../../../../assets/images/logo_mobile.svg';
import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';
import logoStyles from './styles/logoStyles';

const Logo = ({ classes }) => (
  <Link to="/" className={classes.root}>
    <Hidden lgUp>
      <LogoMobileImage className={classes.image} />
    </Hidden>
    <Hidden mdDown>
      <LogoImage className={classes.image} />
    </Hidden>
  </Link>
);

export default withStyles(logoStyles)(Logo);
