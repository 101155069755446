const subTitleStyles = (theme) => ({
  root: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: { marginBottom: theme.spacing(3) },
    [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(2) },
  },
  item: {
    '&:not(:last-child)': {
      [theme.breakpoints.down('md')]: { marginBottom: theme.spacing(3) },
      [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(1) },
    },
  },
  text: { color: theme.palette.ink.ink70 },
});

export default subTitleStyles;
