const subTitleStyles = (theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2),
    },
  },
  text: {
    color: theme.palette.ink.ink0,
  },
});

export default subTitleStyles;
