import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import Home from './pages/Home';
import SmartScratch from './pages/Info/SmartScratch';
import SmartChip from './pages/Info/SmartChip';
import ScrollToTop from './components/ScrollToTop';
import TermsOfUse from './pages/Info/TermsOfUse';
import PrivacyPolicy from './pages/Info/PrivacyPolicy';
import SmartscratchPrivacy from './pages/Legacy/SmartscratchPrivacy';
import NotFound from './pages/NotFound';
import TabletPrivacy from './pages/Legacy/TabletPrivacy';
import TabletPrivacyPA from './pages/Legacy/TabletPrivacyPA';
import theme from './styles/theme';

const App = () => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/smartscratch">
          <SmartScratch />
        </Route>
        <Route exact path="/smart-chip-gaming">
          <SmartChip />
        </Route>
        <Route exact path="/terms-of-use">
          <TermsOfUse />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route
          exact
          path={['/smartscratch/privacy', '/smartscratch/privacy.html']}
        >
          <SmartscratchPrivacy />
        </Route>
        <Route exact path={['/privacy-tablet', '/privacy-tablet.html']}>
          <TabletPrivacy />
        </Route>
        <Route exact path={['/privacy-tablet-pa', '/privacy-tablet-pa.html']}>
          <TabletPrivacyPA />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </ThemeProvider>
  </BrowserRouter>
);

export default App;
