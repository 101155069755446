import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';
import Image from '../../../components/Image';
import Scheme from '../../assets/images/scheme.jpg';
import Features from './components/Features';

const Platform = ({ id }) => (
  <Element name={id} id={id}>
    <Title>The SmartScratch Platform: Powerful. Flexible. Customizable.</Title>
    <Description>
      Using a simple web interface, operators or gaming vendors create and
      monitor SmartScratch programs which define game math, pricing, purchase
      rules, outcome pool, game experiences, and cards.
    </Description>
    <Image src={Scheme} alt="scheme" />
    <Features />
  </Element>
);

export default Platform;
