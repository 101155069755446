import React from 'react';
import classNames from 'classnames';
import { ReactComponent as LinkedInIcon } from '../../../../assets/images/linkedin.svg';
import Typography from '../../../../../../../../components/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import photoStyles from './styles/photoStyles';
import Grid from '@material-ui/core/Grid';

const Photo = ({ classes, className, src, title, subtitle, linkedinUrl }) => (
  <div className={classNames(classes.root, className)}>
    <img className={classes.image} src={src} alt={title} />
    <div className={classes.description}>
      <Typography className={classes.title} color="secondary">
        {title}
      </Typography>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Typography variant="button" className={classes.subtitle}>
            {subtitle}
          </Typography>
        </Grid>
        <Grid item>
          <span className={classes.dot} />
        </Grid>
        <Grid item>
          <a
            href={linkedinUrl}
            target="_blank"
            rel="noreferrer"
            className={classes.linkedin}
          >
            <LinkedInIcon />
          </a>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default withStyles(photoStyles)(Photo);
