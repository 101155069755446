import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';
import Link from '../../../components/Link';

const Scope = ({ id }) => (
  <Element name={id} id={id}>
    <Description>
      Tapcentive, Inc. values and respects the privacy of its customers and
      visitors. Through this Websites Privacy Policy (the “Policy”), Tapcentive,
      Inc. ("Tapcentive", "we" or "us") provides information about how we
      collect, use and transfer personally identifiable data from you through
      Tapcentive’s websites at tapcentive.com, smartscratch.com (collectively
      the “Websites”). Please note that your use of these sites is conditional
      on your acceptance of our{' '}
      <Link href="/terms-of-use" router>
        Terms of Use
      </Link>{' '}
      and is also governed by this Policy.
    </Description>
    <Title>Scope</Title>
    <Description>
      This Policy covers only data collected through the Websites and not any
      other data collection or processing, including, without limitation, data
      collection practices of webpages to which the Websites links, or data that
      we or our affiliates collect offline or through webpages that do not
      display a direct link to this statement.
    </Description>
  </Element>
);

export default Scope;
