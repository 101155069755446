import React from 'react';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Contact from '../components/Contact';
import Footer from '../../../components/Footer';
import Content from '../components/Content';
import PlayerJourney from './components/PlayerJourney';
import Platform from './components/Platform';
import Security from './components/Security';
import Integration from './components/Integration';
import Retail from './components/Retail';
import Markets from './components/Markets';
import Scalability from './components/Scalability';

const sections = [
  {
    id: 'PlayerJourney',
    title: 'Player Journey',
    component: PlayerJourney,
  },
  {
    id: 'Platform',
    title: 'The Platform',
    component: Platform,
  },
  {
    id: 'Security',
    title: 'Bank-grade Security',
    component: Security,
  },
  {
    id: 'Integration',
    title: 'Seamless Integration',
    component: Integration,
  },
  {
    id: 'Retail',
    title: 'Retail Expansion',
    component: Retail,
  },
  {
    id: 'Markets',
    title: 'Emerging Markets',
    component: Markets,
  },
  {
    id: 'Scalability',
    title: 'Modern and Scalable',
    component: Scalability,
  },
];

const SmartScratch = () => (
  <>
    <Header />
    <Banner title="SmartScratch" mobileTitle="SmartScratch" />
    <Content sections={sections} />
    <Contact subtitle="If you are interested in bringing SmartScratch to your market, we want to hear from you." />
    <Footer />
  </>
);

export default SmartScratch;
