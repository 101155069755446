import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';

const LinksWebsites = ({ id }) => (
  <Element name={id} id={id}>
    <Title>Links from these Websites</Title>
    <Description>
      If these Websites contain links to other sites and resources provided by
      third parties, these links are provided for your convenience only. This
      includes links contained in advertisements, including banner
      advertisements and sponsored links. We have no control over the contents
      of those sites or resources, and accept no responsibility for them or for
      any loss or damage that may arise from your use of them. If you decide to
      access any of the third-party websites linked to these Websites, you do so
      entirely at your own risk and subject to the terms and conditions of use
      for such websites.
    </Description>
  </Element>
);

export default LinksWebsites;
