import Image0 from '../images/img_0.png';
import Image1 from '../images/img_1.png';
import Image2 from '../images/img_2.png';
import Image3 from '../images/img_3.png';
import Image4 from '../images/img_4.png';
import Image5 from '../images/img_5.png';
import Image6 from '../images/img_6.png';
import Image7 from '../images/img_7.png';
import Image8 from '../images/img_8.png';
import Image9 from '../images/img_9.png';
import Image10 from '../images/img_10.png';
import Image11 from '../images/img_11.png';
import Image12 from '../images/img_12.png';
import Image13 from '../images/img_13.png';
import Image14 from '../images/img_14.png';
import Image15 from '../images/img_15.png';
import Image16 from '../images/img_16.png';

const animationData = {
  v: '5.7.8',
  fr: 30,
  ip: 0,
  op: 181,
  w: 1128,
  h: 1360,
  nm: 'Hero Animation',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 124, h: 164, u: '', p: Image0, e: 0 },
    { id: 'image_1', w: 279, h: 212, u: '', p: Image1, e: 0 },
    { id: 'image_2', w: 197, h: 204, u: '', p: Image2, e: 0 },
    { id: 'image_3', w: 205, h: 168, u: '', p: Image3, e: 0 },
    { id: 'image_4', w: 110, h: 325, u: '', p: Image4, e: 0 },
    { id: 'image_5', w: 152, h: 237, u: '', p: Image5, e: 0 },
    { id: 'image_6', w: 191, h: 191, u: '', p: Image6, e: 0 },
    { id: 'image_7', w: 134, h: 350, u: '', p: Image7, e: 0 },
    { id: 'image_8', w: 242, h: 534, u: '', p: Image8, e: 0 },
    { id: 'image_9', w: 160, h: 327, u: '', p: Image9, e: 0 },
    { id: 'image_10', w: 280, h: 306, u: '', p: Image10, e: 0 },
    { id: 'image_11', w: 280, h: 575, u: '', p: Image11, e: 0 },
    { id: 'image_12', w: 386, h: 260, u: '', p: Image12, e: 0 },
    { id: 'image_13', w: 120, h: 320, u: '', p: Image13, e: 0 },
    { id: 'image_14', w: 120, h: 300, u: '', p: Image14, e: 0 },
    { id: 'image_15', w: 61, h: 551, u: '', p: Image15, e: 0 },
    { id: 'image_16', w: 225, h: 161, u: '', p: Image16, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Database.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [461.35, 82, 0], ix: 2, l: 2 },
            a: { a: 0, k: [62, 82, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'Database Waves',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [461.5, 129.225, 0], ix: 2, l: 2 },
            a: { a: 0, k: [85, 85, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 57.7, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 170,
          h: 170,
          ip: 0,
          op: 1800,
          st: -20,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'Cloud.png',
          cl: 'png',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [447.807, 175.757, 0], ix: 2, l: 2 },
            a: { a: 0, k: [139.5, 106, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'Games.png',
          cl: 'png',
          refId: 'image_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [707, 376.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [98.5, 102, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: 'Cards.png',
          cl: 'png',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [225, 371.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [102.5, 84, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: 'Phone Screen Mask.png',
          cl: 'png',
          refId: 'image_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [289, 796.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [55, 162.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Data L Mask 2',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [290.497, 798.905, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [-79.878, 79.907, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-3.85, 2.15],
                        [0, 0],
                        [0, 0],
                        [-0.06, 4.49],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 8.57],
                        [0, 0],
                        [-7.41, 4.28],
                        [0, 0],
                      ],
                      o: [
                        [0, 4.46],
                        [0, 0],
                        [0, 0],
                        [3.87, -2.23],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-7.42, 4.28],
                        [0, 0],
                        [0, -8.57],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-36.541, -140.916],
                        [-29.591, -136.766],
                        [-29.471, -136.836],
                        [29.549, -170.936],
                        [36.619, -183.046],
                        [36.619, -183.186],
                        [36.629, -186.856],
                        [52.879, -196.246],
                        [61.071, -319.35],
                        [411.837, -253.572],
                        [526.932, 272.111],
                        [106.631, 394.247],
                        [-52.881, 196.254],
                        [-66.311, 188.494],
                        [-66.231, -111.926],
                        [-52.801, -135.196],
                        [-36.541, -144.586],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 0,
          nm: 'Data-Phone',
          tt: 1,
          refId: 'comp_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [505, 610.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [505, 610.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 1010,
          h: 1221,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 2,
          nm: 'Phone-Left.png',
          cl: 'png',
          refId: 'image_7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [293.874, 795.292, 0], ix: 2, l: 2 },
            a: { a: 0, k: [67, 175, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 2,
          nm: 'Tablet Screen Mask.png',
          cl: 'png',
          refId: 'image_8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [704, 875, 0], ix: 2, l: 2 },
            a: { a: 0, k: [121, 267, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Data F Mask',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [703.321, 875.75, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80.059, 79.943, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.51, 2.03],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 4.06],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.51, -2.03],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.51, 2.03],
                        [0, 0],
                        [0, -4.06],
                      ],
                      v: [
                        [-141.072, -162.279],
                        [141.068, -325.169],
                        [489.677, -374.036],
                        [489.677, 473.98],
                        [9.914, 466.247],
                        [-141.072, 325.171],
                        [-147.432, 321.491],
                        [-147.432, -151.259],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle Copy 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 0,
          nm: 'Data-Tablet',
          tt: 1,
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [505, 610.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [505, 610.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 1010,
          h: 1221,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 2,
          nm: 'Tablet-Front.png',
          cl: 'png',
          refId: 'image_11',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [699, 872.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [140, 287.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 2,
          nm: 'POS.png',
          cl: 'png',
          refId: 'image_12',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [255, 1091, 0], ix: 2, l: 2 },
            a: { a: 0, k: [193, 130, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 0,
          nm: 'Connection+Data',
          refId: 'comp_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [461.5, 367, 0], ix: 2, l: 2 },
            a: { a: 0, k: [59.5, 158, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 119,
          h: 316,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 2,
          nm: 'Chip.png',
          cl: 'png',
          refId: 'image_16',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [460.5, 516.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [112.5, 80.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 0,
          nm: 'Chip Waves',
          refId: 'comp_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [461.5, 533.75, 0], ix: 2, l: 2 },
            a: { a: 0, k: [200, 110, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 400,
          h: 220,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 0,
          nm: 'Connections',
          refId: 'comp_7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [466.5, 754, 0], ix: 2, l: 2 },
            a: { a: 0, k: [300, 400, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 600,
          h: 800,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'W Shape Layer 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0.167] },
                  t: 20,
                  s: [100],
                },
                { t: 50, s: [0] },
              ],
              ix: 11,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [85, 85, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667], y: [1, 1] },
                        o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                        t: 20,
                        s: [120, 120],
                      },
                      { t: 50, s: [170, 170] },
                    ],
                    ix: 2,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();',
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 20,
          op: 1820,
          st: 20,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'W Shape Layer 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0.167] },
                  t: 10,
                  s: [100],
                },
                { t: 40, s: [0] },
              ],
              ix: 11,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [85, 85, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667], y: [1, 1] },
                        o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                        t: 10,
                        s: [120, 120],
                      },
                      { t: 40, s: [170, 170] },
                    ],
                    ix: 2,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();',
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 10,
          op: 1820,
          st: 10,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'W Shape Layer 1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [100],
                },
                { t: 30, s: [0] },
              ],
              ix: 11,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [85, 85, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667], y: [1, 1] },
                        o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                        t: 0,
                        s: [120, 120],
                      },
                      { t: 30, s: [170, 170] },
                    ],
                    ix: 2,
                    x: 'var $bm_rt;\n$bm_rt = loopOut();',
                  },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1820,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Data-Left-2.png',
          cl: 'png',
          refId: 'image_5',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: -30,
                  s: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [100],
                },
                { t: 30, s: [0] },
              ],
              ix: 11,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -30,
                  s: [393.5, 672.5, 0],
                  to: [-50, 30, 0],
                  ti: [50, -30, 0],
                },
                { t: 30, s: [93.5, 852.5, 0] },
              ],
              ix: 2,
              l: 2,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            a: { a: 0, k: [76, 118.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Data-Left-1.png',
          cl: 'png',
          refId: 'image_6',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: -15,
                  s: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 15,
                  s: [100],
                },
                { t: 45, s: [0] },
              ],
              ix: 11,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: -15,
                  s: [456, 742.5, 0],
                  to: [-79.289, 47.574, 0],
                  ti: [49.92, -29.952, 0],
                },
                { t: 45, s: [156.483, 922.21, 0] },
              ],
              ix: 2,
              l: 2,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            a: { a: 0, k: [95.5, 95.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Data-Front-1.png',
          cl: 'png',
          refId: 'image_9',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 30,
                  s: [0],
                },
                { t: 60, s: [0] },
              ],
              ix: 11,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [674.307, 914.332, 0],
                  to: [66.667, 40, 0],
                  ti: [-66.667, -40, 0],
                },
                { t: 60, s: [1074.307, 1154.332, 0] },
              ],
              ix: 2,
              l: 2,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            a: { a: 0, k: [80, 163.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Data-Front-2.png',
          cl: 'png',
          refId: 'image_10',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 30,
                  s: [100],
                },
                { t: 60, s: [0] },
              ],
              ix: 11,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [458.342, 605.864, 0],
                  to: [66.667, 40, 0],
                  ti: [-66.667, -40, 0],
                },
                { t: 60, s: [858.342, 845.864, 0] },
              ],
              ix: 2,
              l: 2,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            a: { a: 0, k: [140, 153, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'Data-Front-1.png',
          cl: 'png',
          refId: 'image_9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [274.307, 674.332, 0],
                  to: [66.667, 40, 0],
                  ti: [-66.667, -40, 0],
                },
                { t: 60, s: [674.307, 914.332, 0] },
              ],
              ix: 2,
              l: 2,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            a: { a: 0, k: [80, 163.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_4',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Glow Chip.png',
          cl: 'png',
          refId: 'image_13',
          sr: 1,
          ks: {
            o: { a: 0, k: 40, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [59.5, 156, 0], ix: 2, l: 2 },
            a: { a: 0, k: [60, 160, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Glow Chip Mask.png',
          cl: 'png',
          td: 1,
          refId: 'image_14',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [59.5, 158, 0], ix: 2, l: 2 },
            a: { a: 0, k: [60, 150, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'Data-Top.png',
          cl: 'png',
          tt: 1,
          refId: 'image_15',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              s: true,
              x: { a: 0, k: 59.5, ix: 3 },
              y: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 0,
                    s: [230],
                  },
                  { t: 60, s: [47] },
                ],
                ix: 4,
                x: 'var $bm_rt;\n$bm_rt = loopOut();',
              },
            },
            a: { a: 0, k: [30.5, 275.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_5',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'Chip Waves Top',
          refId: 'comp_6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [200, 110, 0], ix: 2, l: 2 },
            a: { a: 0, k: [360, 360, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 57.7, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 720,
          h: 720,
          ip: 0,
          op: 1800,
          st: -20,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_6',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0.167] },
                  t: 20,
                  s: [100],
                },
                { t: 50, s: [0] },
              ],
              ix: 11,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            r: { a: 0, k: 45, ix: 10 },
            p: { a: 0, k: [360, 360, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 20,
                    s: [170, 170],
                  },
                  { t: 50, s: [290, 290] },
                ],
                ix: 2,
                x: 'var $bm_rt;\n$bm_rt = loopOut();',
              },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: {
                a: 0,
                k: 25,
                ix: 4,
                x: "var $bm_rt;\n$bm_rt = $bm_sum($bm_div($bm_sub(content('Rectangle Path 1').size[0], 170), 2), 25);",
              },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.92549020052, 0.32549020648, 0.254901975393, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 20,
          op: 1820,
          st: 20,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0.167] },
                  t: 10,
                  s: [100],
                },
                { t: 40, s: [0] },
              ],
              ix: 11,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            r: { a: 0, k: 45, ix: 10 },
            p: { a: 0, k: [360, 360, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 10,
                    s: [170, 170],
                  },
                  { t: 40, s: [290, 290] },
                ],
                ix: 2,
                x: 'var $bm_rt;\n$bm_rt = loopOut();',
              },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: {
                a: 0,
                k: 25,
                ix: 4,
                x: "var $bm_rt;\n$bm_rt = $bm_sum($bm_div($bm_sub(content('Rectangle Path 1').size[0], 170), 2), 25);",
              },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.92549020052, 0.32549020648, 0.254901975393, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 10,
          op: 1820,
          st: 10,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [100],
                },
                { t: 30, s: [0] },
              ],
              ix: 11,
              x: 'var $bm_rt;\n$bm_rt = loopOut();',
            },
            r: { a: 0, k: 45, ix: 10 },
            p: { a: 0, k: [360, 360, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [170, 170],
                  },
                  { t: 30, s: [290, 290] },
                ],
                ix: 2,
                x: 'var $bm_rt;\n$bm_rt = loopOut();',
              },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: {
                a: 0,
                k: 25,
                ix: 4,
                x: "var $bm_rt;\n$bm_rt = $bm_sum($bm_div($bm_sub(content('Rectangle Path 1').size[0], 170), 2), 25);",
              },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.92549020052, 0.32549020648, 0.254901975393, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 1820,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_7',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Dash 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [365.611, 507.402, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 13.53],
                      ],
                      o: [
                        [0, 0],
                        [-11.71, -6.76],
                        [0, 0],
                      ],
                      v: [
                        [70.709, 53.068],
                        [-49.501, -16.332],
                        [-70.711, -53.072],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.941175997257, 0.235293999314, 0.27058801055, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  d: [
                    { n: 'd', nm: 'dash', v: { a: 0, k: 30, ix: 1 } },
                    { n: 'g', nm: 'gap', v: { a: 0, k: 30, ix: 2 } },
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 1799.5,
                            s: [3000],
                          },
                          { t: 1800, s: [100] },
                        ],
                        ix: 7,
                      },
                    },
                  ],
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Dash 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Dash 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [204.211, 434.402, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 13.53],
                      ],
                      o: [
                        [0, 0],
                        [11.71, -6.76],
                        [0, 0],
                      ],
                      v: [
                        [-90.711, 65.068],
                        [69.499, -28.332],
                        [90.709, -65.072],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.941175997257, 0.235293999314, 0.27058801055, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  d: [
                    { n: 'd', nm: 'dash', v: { a: 0, k: 30, ix: 1 } },
                    { n: 'g', nm: 'gap', v: { a: 0, k: 30, ix: 2 } },
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 1799.5,
                            s: [3000],
                          },
                          { t: 1800, s: [100] },
                        ],
                        ix: 7,
                      },
                    },
                  ],
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Dash 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Dash 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [397.984, 203.825, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [11.71, -6.77],
                        [0, 0],
                        [0, 13.53],
                      ],
                      o: [
                        [0, 0],
                        [0, 13.53],
                        [0, 0],
                        [-11.71, 6.77],
                        [0, 0],
                      ],
                      v: [
                        [153.209, -152.701],
                        [153.209, -28.499],
                        [131.999, 8.251],
                        [-82.001, 134.401],
                        [-103.211, 122.151],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.941175997257, 0.235293999314, 0.27058801055, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  d: [
                    { n: 'd', nm: 'dash', v: { a: 0, k: 30, ix: 1 } },
                    { n: 'g', nm: 'gap', v: { a: 0, k: 30, ix: 2 } },
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 1799.5,
                            s: [3000],
                          },
                          { t: 1800, s: [100] },
                        ],
                        ix: 7,
                      },
                    },
                  ],
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Dash 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Dash 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [196.711, 174.299, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-11.71, -6.77],
                        [0, 0],
                        [0, 13.53],
                      ],
                      o: [
                        [0, 0],
                        [0, 13.53],
                        [0, 0],
                        [11.71, 6.77],
                        [0, 0],
                      ],
                      v: [
                        [-148.211, -135.102],
                        [-148.211, -25.499],
                        [-127.001, 11.251],
                        [76.999, 131.401],
                        [98.209, 119.151],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.941175997257, 0.235293999314, 0.27058801055, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  d: [
                    { n: 'd', nm: 'dash', v: { a: 0, k: 30, ix: 1 } },
                    { n: 'g', nm: 'gap', v: { a: 0, k: 30, ix: 2 } },
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 1799.5,
                            s: [3000],
                          },
                          { t: 1800, s: [100] },
                        ],
                        ix: 7,
                      },
                    },
                  ],
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Dash 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Dash 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [224.211, 472.474, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 13.7],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [11.71, -6.77],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-70.711, 287.476],
                        [49.499, 218.076],
                        [70.709, 181.326],
                        [70.709, -287.474],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.941175997257, 0.235293999314, 0.27058801055, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  d: [
                    { n: 'd', nm: 'dash', v: { a: 0, k: 30, ix: 1 } },
                    { n: 'g', nm: 'gap', v: { a: 0, k: 30, ix: 2 } },
                    {
                      n: 'o',
                      nm: 'offset',
                      v: {
                        a: 1,
                        k: [
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 0,
                            s: [0],
                          },
                          {
                            i: { x: [0.833], y: [0.833] },
                            o: { x: [0.167], y: [0.167] },
                            t: 1799.5,
                            s: [3000],
                          },
                          { t: 1800, s: [100] },
                        ],
                        ix: 7,
                      },
                    },
                  ],
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Dash 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Solid 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [365.611, 507.402, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-11.71, -6.76],
                        [0, 0],
                      ],
                      o: [
                        [0, 13.53],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-70.711, -53.072],
                        [-49.501, -16.332],
                        [70.709, 53.068],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 0.498039007187, 0.44313699007, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 12, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Solid 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Solid 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [204.211, 434.402, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 13.53],
                      ],
                      o: [
                        [0, 0],
                        [11.71, -6.76],
                        [0, 0],
                      ],
                      v: [
                        [-90.711, 65.068],
                        [69.499, -28.332],
                        [90.709, -65.072],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 0.498039007187, 0.44313699007, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 12, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Solid 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Solid 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [398.111, 203.825, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-11.71, 6.77],
                        [0, 0],
                        [0, 13.53],
                        [0, 0],
                      ],
                      o: [
                        [0, 13.53],
                        [0, 0],
                        [11.71, -6.77],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-103.211, 122.151],
                        [-82.001, 134.401],
                        [131.999, 8.251],
                        [153.209, -28.499],
                        [153.209, -123.504],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 0.498039007187, 0.44313699007, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 12, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Solid 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Solid 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [196.711, 174.299, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-11.71, -6.77],
                        [0, 0],
                        [0, 13.53],
                      ],
                      o: [
                        [0, 0],
                        [0, 13.53],
                        [0, 0],
                        [11.71, 6.77],
                        [0, 0],
                      ],
                      v: [
                        [-148.211, -135.102],
                        [-148.211, -25.499],
                        [-127.001, 11.251],
                        [76.999, 131.401],
                        [98.209, 119.151],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 0.498039007187, 0.44313699007, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 12, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Solid 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Solid 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [224.211, 472.474, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 13.53],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [11.71, -6.77],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-70.711, 287.476],
                        [49.499, 218.076],
                        [70.709, 181.326],
                        [70.709, -287.474],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 0.498039007187, 0.44313699007, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 12, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Solid 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Hero',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [564, 680, 0], ix: 2, l: 2 },
        a: { a: 0, k: [505, 610.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1010,
      h: 1221,
      ip: 0,
      op: 1800,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

export default animationData;
