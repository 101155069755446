import React from 'react';
import { Element } from 'react-scroll';
import Description from '../../../components/Description';
import Title from '../../../components/Title';
import withStyles from '@material-ui/core/styles/withStyles';
import scalabilityStyles from './styles/scalabilityStyles';

const Scalability = ({ classes, id }) => (
  <Element name={id} id={id} className={classes.root}>
    <Title>A Modern System That Scales with Your Growth.</Title>
    <Description>
      The SmartScratch gaming platform is a state-of-the art high volume, high
      security transaction processing system that scales easily to meet the most
      demanding markets. The architecture and design enable SmartScratch to be
      deployed in a public cloud as easily as it can be deployed to dedicated
      data centers to meet local regulatory and operator requirements. The
      platform is also flexible enough to be easily customized and integrated
      with existing operator and vendor systems.
    </Description>
  </Element>
);

export default withStyles(scalabilityStyles)(Scalability);
